import React, { Component } from "react";
import {
  Box,
  Typography,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import banner from "../images/banner.png";

const useStyles = makeStyles(() => ({
  sd_banner_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 1), rgba(255,255,255, 0.1)), url(${banner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "white",
    height: "200px",
  },
  sd_banner_center: {
    textAlign: "center",
    margin: "auto auto 0 auto",
    display: "flex",
    gap: "20px",
  },
  sd_banner_rotate_360: {
    padding: "5px 50px",
    border: "1px solid #00000000",
    borderRadius: "10px 10px 0px 0px",
    background: "#53B0AE",
  },
  sd_banner_2D: {
    padding: "5px 50px",
    border: "1px solid #00000000",
    borderRadius: "10px 10px 0px 0px",
    background: "#00000080",
  },
}));

function Banner() {
  const styles = useStyles();

  return (
    <Box className={styles.sd_banner_wrapper}>
      <Box className={styles.sd_banner_center}>
        <Box className={styles.sd_banner_rotate_360}>
          <Typography variant="body1">360照片</Typography>
        </Box>
        <Box className={styles.sd_banner_2D}>
          <Typography variant="body1">2D照片</Typography>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles1 = (theme) => ({
  sd_body_wrapper: {
    display: "flex",
    padding: "20px 100px",
    justifyContent: "center",
  },
  sd_room_info_wrapper: {
    display: "grid",
    gap: "30px",
    margin: "30px 30px 30px 0px",
    width: "60%",
    minWidth: "400px",
  },
  sd_room_info_header_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sd_room_info_header_fav: {
    border: "1.8px solid black",
    borderRadius: "5px",
    padding: "5px 20px",
  },
  sd_room_info_header_ws: {
    border: "1.8px solid #59CE72",
    borderRadius: "5px",
    padding: "5px 20px",
  },
  sd_room_info_content_wrapper: {
    height: "150px",
    width: "150px",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sd_list_wrapper: {
    display: "flex",
    gap: "30px",
    overflow: "hidden",
  },
  sd_list: {
    display: "flex",
    flexDirection: "row",
    padding: "0",
    overflowX: "scroll",
    width: "100%",
  },
  sd_list_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80px",
    width: "80px",
    border: "1px solid #00000000",
    borderRadius: "50%",
    background: "white",
    marginBottom: "20px",
  },
  sd_send_appointment_wrapper: {
    marginTop: "30px",
    marginBottom: "30px",
    width: "40%",
    minWidth: "350px",
  },
  sd_send_appointment: {
    background: "white",
    padding: "20px",
    display: "grid",
    gap: "20px",
    border: "1px solid white",
    borderRadius: "10px",
  },
  sd_send_appointment_button: {
    display: "flex",
    justifyContent: "center",
    background: "#53B0AE",
    color: "white",
    border: "1px solid #00000000",
    borderRadius: "10px",
    padding: "10px",
  },
});

class StudioDetail extends Component {
  state = {
    facilityList: [
      { id: 1, name: "冷氣" },
      { id: 2, name: "鏡" },
      { id: 3, name: "⾳響" },
      { id: 4, name: "燈光" },
      { id: 5, name: "瑜珈磚" },
      { id: 6, name: "瑜珈磚" },
      { id: 7, name: "瑜珈磚" },
      { id: 8, name: "瑜珈磚" },
    ],
  };

  render() {
    const { classes } = this.props;

    return (
      <Box sx={{ background: "#F5F5F5" }}>
        <Toolbar />
        <Banner />
        <Box className={classes.sd_body_wrapper}>
          <Box className={classes.sd_room_info_wrapper}>
            <Box className={classes.sd_room_info_header_wrapper}>
              <Typography variant="h4">舞室資料</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box className={classes.sd_room_info_header_fav}>
                  <Typography variant="body2">加入我的喜愛</Typography>
                </Box>
                <Box className={classes.sd_room_info_header_ws}>
                  <Typography variant="body2">以Whatsapp 查詢</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box className={classes.sd_room_info_content_wrapper}>
                <Typography variant="h5">Image</Typography>
              </Box>
              <Box sx={{ display: "grid", gap: "10px" }}>
                <Typography variant="h5">Room A</Typography>
                <Typography variant="body1">In Motion Dance Studio</Typography>
                <Typography variant="body2">開放時間</Typography>
                <Typography variant="body2">星期⼀：07:00-23:59</Typography>
                <Typography variant="body2">星期⼆：07:00-23:59</Typography>
                <Typography variant="body2">星期三：07:00-23:59</Typography>
                <Typography variant="body2">星期四：07:00-23:59</Typography>
                <Typography variant="body2">星期五：07:00-23:59</Typography>
                <Typography variant="body2">星期六：07:00-23:59</Typography>
                <Typography variant="body2">星期⽇：07:00-23:59</Typography>
                <Typography variant="body2">
                  ⽯⾨安麗街11號企業中⼼A座17樓1707室
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "grid", gap: "20px" }}>
              <Typography variant="h4">場地資料</Typography>
              <Box sx={{ display: "grid", gap: "10px" }}>
                <Typography variant="body2">650尺</Typography>
                <Typography variant="body2">20⼈或以下</Typography>
                <Typography variant="body2">需提前預約的時: 12⼩時</Typography>
                <Typography variant="body2">最低消費時間：1⼩時</Typography>
                <Typography variant="body2">⾃助舞室</Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "grid", gap: "20px" }}>
              <Typography variant="h4">設施</Typography>
              <Box className={classes.sd_list_wrapper}>
                <List className={classes.sd_list}>
                  {this.state.facilityList.map((facility) => {
                    return (
                      <ListItem key={facility.id}>
                        <Box sx={{ textAlign: "center" }}>
                          <Box className={classes.sd_list_item}>
                            <Typography variant="body2">Image</Typography>
                          </Box>
                          <Typography variant="body2">
                            {facility.name}
                          </Typography>
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "grid", gap: "20px" }}>
              <Typography variant="h4">其他</Typography>
              <Box sx={{ display: "grid", gap: "15px" }}>
                <Typography variant="h5">場地預約流程</Typography>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <FiberManualRecordIcon style={{ width: 3 }} />
                  <Typography variant="body2">
                    當你經 Diggin’
                    發送預約查詢後，場地會立即收到查詢要求並與你開始⼀個新的對話空間
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <FiberManualRecordIcon style={{ width: 3 }} />
                  <Typography variant="body2">
                    ⼀般場地會在 2-3 個⼩時內回覆或致電給你
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <FiberManualRecordIcon style={{ width: 3 }} />
                  <Typography variant="body2">
                    當場地確認並提供了空檔期，他們便會向你收取按⾦，並且確認你的場地預約
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <FiberManualRecordIcon style={{ width: 3 }} />
                  <Typography variant="body2">
                    如果場地在長時間都未有回覆，可按此聯絡我們，並由我們 2book
                    的同事幫你跟進
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box className={classes.sd_send_appointment_wrapper}>
            <Box className={classes.sd_send_appointment}>
              <Box sx={{ display: "grid", gap: "10px" }}>
                <Typography variant="h4">立即發出預約查詢</Typography>
                <Box>
                  <Typography variant="body2">
                    平台將會即時接收你提交的查詢, 100%免費
                  </Typography>
                  <Typography variant="body2">報價查詢服務</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "grid", gap: "10px" }}>
                <Typography variant="h5">場地費⽤</Typography>
                <Box>
                  <Typography variant="body1">
                    星期⼀⾄五 時租HKD$150
                  </Typography>
                  <Typography variant="body1">
                    公眾假期及周未 時租HKD$250
                  </Typography>
                </Box>
              </Box>
              <form>
                <Box sx={{ display: "grid", gap: "20px" }}>
                  <Box sx={{ display: "grid", gap: "10px" }}>
                    <Typography variant="h5">⽇期</Typography>
                    <TextField
                      id="date_textfield"
                      placeholder="請選擇⽇期"
                      variant="outlined"
                      //type="date"
                    />
                  </Box>
                  <Box sx={{ display: "grid", gap: "10px" }}>
                    <Typography variant="h5">開始時間</Typography>
                    <TextField
                      id="start_time_textfield"
                      placeholder="請選擇時間"
                      variant="outlined"
                      //type="time"
                    />
                  </Box>
                  <Box sx={{ display: "grid", gap: "10px" }}>
                    <Typography variant="h5">結束時間</Typography>
                    <TextField
                      id="end_time_textfield"
                      placeholder="請選擇時間"
                      variant="outlined"
                      //type="time"
                    />
                  </Box>
                  <Box className={classes.sd_send_appointment_button}>
                    <Typography variant="h5">立即查詢</Typography>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
          <Divider />
        </Box>
      </Box>
    );
  }
}

export default withStyles(useStyles1)(StudioDetail);
