import React from 'react'
import { AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from '../images/logo_white_100.png';

const useStyles = makeStyles(() => ({
  typographyStyles: {
    flex: 1
  },
  whiteColor: {
    color: '#ffffff',
    fontSize: 22,
  },
  appbar: {
    alignItems: 'center',
  }
}));

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar color="primary" className={classes.appbar} position="fixed">
      <Toolbar>
        <img width="44" height="44" src={logo} />
          <Typography className={classes.whiteColor}>
              Diggin'
          </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
