import React from "react";
import { Route, Switch } from "react-router-dom";
import { Grid } from "@mui/material";
import Homepage from "./Home/Home";
import Event from "./Event";
import EventDetails from "./Event/EventDetails";
import EventApplication from "./Event/Application/EventApplicationForm";
import { EventApplicationSuccess } from "./Messages/EventApplicationSuccess.jsx";
import AddEventForm from "./Event/EventManage/AddEventForm";
import NotAvailable from "./Messages/NotAvailable.js";
import LoginPage from "./LoginPage/Login";
import RegisterPage from "./RegisterPage/Register";
import AboutUs from "./AboutUs/AboutUs";
import { Terms } from "./TermsOfUse";
import { TutorialsTerms } from "./TermsOfUse/tutorials_tnc.jsx";
import { CardAgreement } from "./TermsOfUse/card_agreement.jsx";
import { Privacy } from "./Privacy";
import { JoinUs } from "./JoinUs";
import { Clients } from "./Clients";
import { ClientsOthers } from "./Clients/clients_others";
import { EventRecords } from "./EventRecords";
import { EventRecordDetails } from "./EventRecords/DetailsIndex";
import { EventCategoryRecords } from "./EventRecords/EventCategoryRecords.jsx";

import StudioList from "./StudioList";
import StudioDetail from "./StudioDetail";

const Root = () => {
  return (
    <Grid container direction="column">

      <Switch>
        <Route component={Homepage} path="/home" />

        <Route
          component={Event}
          path="/events/:countryId/:districtCatId/:districtId"
        />
        <Route component={EventDetails} path="/event/:event_public_id" />
        <Route component={EventDetails} path="/share/event/:event_public_id" />
        <Route
          component={EventApplication}
          path="/event_apply/:event_public_id/:categoryId"
        />

        {/* <Route
          component={AddEventForm}
          path="/addevent"
        /> */}
        
        {/* <Route component={EventRecords} path="/events_records" />
        <Route component={EventRecordDetails} path="/record/:event_public_id" /> */}
        {/* <Route
          component={EventCategoryRecords}
          path="/record_details/:category_id/:selection_mode"
        /> */}
        <Route component={LoginPage} path="/login" />
        <Route component={RegisterPage} path="/register" />
        <Route component={AboutUs} path="/about_us" />
        <Route component={JoinUs} path="/join_us" />
        <Route component={Terms} path="/terms" />
        <Route component={TutorialsTerms} path="/tutorials_terms" />
        <Route component={CardAgreement} path="/card_agreement" />
        <Route component={Privacy} path="/privacy" />
        <Route component={Clients} path="/clients" />
        <Route component={ClientsOthers} path="/clients_others" />

        <Route component={EventApplicationSuccess} 
          path="/apply_success/:event_public_id/:categoryId"
        />

        {/* <Route component={StudioList} path="/studioList/" />
        <Route component={StudioDetail} path="/studioDetail/" /> */}

        <Route component={Homepage} path="*" />
        
      </Switch>
      
    </Grid>
  );
};

export default Root;
