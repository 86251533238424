import React, { Component } from 'react'
import { Container, Grid } from '@mui/material';
import Content from './Content';
import HomepageHeader from '../Header/HomepageHeader';
import HomepageFooter from '../Footers/HomepageFooter';
import Header from '../Header/Header';
import DownloadFooter from '../Footers/DownloadFooter';
import { Navbar } from '../Navbar';
import styled from "styled-components";
import i18n from '../../../i18n';
import { withTranslation } from "react-i18next";
import DistrictCategory from './DistrictCategory';
import District from './District';

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0 1.5em;
  background-color: #fff;
  white-space: pre-line;
  fontFamily: "Noto Sans",
`;

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const ContentText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const FormButton = styled.button`
  border: 0;
  outline: 0;
  padding: 8px 1em;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 18px;
  background-color: #53B0AE;
  cursor: pointer;
`;

class Event extends Component {

  constructor() {
  super();
  }

  state = {
    countryId: 0,
  }

  componentDidMount() {
    this.setState({
      countryId: this.props.match.params.countryId,
      districtCatId: this.props.match.params.districtCatId,
    });
  }

  render() {
    const { t } = this.props;
    var { countryId, districtCatId } = this.state;

    return(
      <div>
      <Navbar />

      <div style={{marginTop: 120}}/>
      <EventsContainer>
      <TitleText>{t('coming_events')}</TitleText>
      <br/>
      <ContentText>
      <a style={{marginRight: 12}} href="/events/99/0/0"><FormButton>{t('hong_kong')}</FormButton></a>
      <a style={{marginRight: 12}} href="/events/235/0/0"><FormButton>{t('taiwan')}</FormButton></a>
      <a href="/events/203/0/0"><FormButton>{t('singapore')}</FormButton></a>
      
      </ContentText>
      <div style={{marginTop: 20}}/>

      <ContentText>
      <DistrictCategory countryId={countryId} />
      </ContentText>
      <div style={{marginTop: 20}}/>
      <ContentText>
      <District districtCatId={districtCatId} />
      </ContentText>

      <div style={{marginTop: 50}}/>



      <Grid item container>
          <Grid item xs={0} sm={2} />
            <Grid item xs={12} sm={8}>
              <Container style={{padding:"6px"}}>
                <Content {...this.props}/>
              </Container>
            </Grid>
          <Grid item xs={0} sm={2} />
      </Grid>


      <div style={{marginBottom: 100}}/>
      </EventsContainer>
      <HomepageFooter />
      </div>
    );
  };
}

export default withTranslation()(Event);
