import React from "react";
import styled from "styled-components";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive'
import EventPage from "./EventPage";
import EventPageMobile from "./EventPageMobile";

export function EventDetailsMain(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 992px)'
  })
  return (
    <div>
    {!isMobile && <EventPage {...props}/>}
    {isMobile && <EventPageMobile {...props}/>}
    </div>
  );
}
