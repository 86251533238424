import React from 'react'
import { makeStyles } from '@mui/styles';
import ImageList from '@mui/material/ImageList';
import StudioContent from './StudioContent';

const useStyles = makeStyles(() => ({
  imageList: {
    flexWrap: 'nowrap',
    margin: "0px !important",
    transform: 'translateZ(0)',
    width: "100%",
    height: "100%",
    flexDirection: "row"
  }
}));

function StudioPage () {
  const classes = useStyles();
    return (
      <ImageList className={classes.imageList} >
        <StudioContent />
      </ImageList>
  );
};

export default StudioPage;
