import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Container } from '@mui/material';
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  card: {
    minWidth: 335,
    height: 265,
    margin: "0px 20px 0px 0px",
    display: 'flex',
    borderRadius: "10px",
    '&:hover': {
      border:'2px solid #53B0AE',
      background: "#FFF",
    },
  },

  image: {
    width: "150px !important",
    padding: "10px",
    borderRadius: "18px"
  },

  details: {
    display: 'flex',
    flexDirection: "column",
    height:"100%",
    width: "100%"
  },

  cardaction: {
    height:"100%",
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },

  content: {
    padding: "10px !important",
    height: "75%"
  },

});

const HomepageEventCard = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pid, imageUrl, eventName, eventAddress, startTime, endTime, eventTypeChinese, eventTypeEng } = props;
  return(

    <Card className={classes.card}>

      <div className={classes.details}>
        <CardActionArea className={classes.cardaction} href={'/event/'+pid }>

          <CardMedia
            className={classes.image}
            component="img"
            alt={eventName}
            height="245"
            image={imageUrl}
            title={eventName}
          />

          <CardContent className={classes.content}>
            <Typography variant="body2"  component="p" style={{fontWeight: "bold", height: 10}}>
              {t(String(eventTypeEng))}

            </Typography>
            <p></p>

            <Typography gutterBottom variant="body1" component="h2" style={{fontWeight: "bold", height: 80}}>
              {eventName.length > 43 ? `${eventName.substring(0, 43)}...` : eventName}
            </Typography>

            <Container style={{padding: "15px 0px"}}>
              <Typography variant="caption" component="p">
              <div style={{display:"flex"}}>
                <RoomOutlinedIcon style={{fontSize:"medium"}}/>
                <Container style={{padding: "0px 0px 0px 10px"}}>
                {eventAddress.length > 18 ? `${eventAddress.substring(0, 18)}...` : eventAddress}
                </Container>
                </div>
              </Typography>
              <p></p>
              <Typography variant="caption" component="p">
                <div style={{display:"flex"}}>
                  <AccessTimeIcon style={{fontSize:"medium"}}/> <Container style={{padding: "0px 0px 0px 10px"}}> {startTime.slice(0, -3).replaceAll("-", ".")} - {endTime.slice(0, -3).replaceAll("-", ".")} </Container>
                </div>
              </Typography>
            </Container>
          </CardContent>
        </CardActionArea>
      </div>
    </Card>
  );
}

export default HomepageEventCard;
