import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import i18n from '../../../../i18n';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive'

import { TextField, Container, Link } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ImageUpload from "../../ImageUpload";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ isMobile }) => isMobile ? '20px' : '180px'};
  margin-right: ${({ isMobile }) => isMobile ? '20px' : '180px'};
  padding: 0 1.5em;
  background-color: #fff;
  white-space: pre-line;
  fontFamily: "Noto Sans",
`;

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const Button = styled.button`
  background-color: ${(props) => props.theme?.default};
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  border: 0;
  height: 44px;
  width: 100%;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => props.theme?.hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export function AddEventFormBody(props) {
  
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 992px)'
  })
  const [eventName, setEventName] = useState("");
  // const [value, setValue] = React.useState<Dayjs | null>(null);

  const handleEventNameChange = (event) => {
    setEventName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform the POST request here
  };


  {/* {!isMobile && <div>222</div>}
    {isMobile &&<div>333</div>} */}
  return (
    <div>
      
      <PageContainer isMobile={isMobile}>
        <TitleText>{t('upload_event')}</TitleText>
        <br /><br />
          <form onSubmit={handleSubmit}>
            <p></p>

              <TextField
                    id="outlined-basic"
                    label={t("event_name")}
                    variant="outlined"
                    fullWidth
                    name="event_name"
                    onChange={(event) => handleEventNameChange(event, setEventName)}
                    required
              />
              <p></p>

              <FormControl variant="outlined" fullWidth>
                        <InputLabel id={`event_type`}>{t("event_type")}</InputLabel>
                        <Select
                          labelId={`event_type`}
                          id={`event_type`}
                          label="event_type"
                          // onChange={(e) => this.handleGenderChange(e, index)}
                          required
              >
                <MenuItem value="M">{t("male")}</MenuItem>
                <MenuItem value="F">{t("female")}</MenuItem>
                </Select>
              </FormControl>
              <p></p>
              <FormControl variant="outlined" fullWidth>
                        <InputLabel id={`district`}>{t("district")}</InputLabel>
                        <Select
                          labelId={`district`}
                          id={`district`}
                          label="district"
                          // onChange={(e) => this.handleGenderChange(e, index)}
                          required
                        >
                <MenuItem value="M">{t("male")}</MenuItem>
                <MenuItem value="F">{t("female")}</MenuItem>
                        </Select>
              </FormControl>
              <p></p>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DatePicker /> */}
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                <DateTimePicker/>
                <div style={{ display: 'flex', marginRight: '20px' }}></div>
                <br/><br/>
                <DateTimePicker/>
                </div>
              </LocalizationProvider>
              <p></p>
              <ImageUpload/>
              <p></p>
              
              <TextField
                id="outlined-multiline-static"
                label={t("description")}
                name="description"
                multiline
                rows={12}
                fullWidth
                required
              />
     
             

            <p></p>
       
            <br />
            <Button type="submit" theme={{ default: "#53B0AE", hover: "#53B0AE" }}>{t("confirm")}</Button>
            {/* <Button type="submit">{t("confirm")}</Button> */}
          </form>
      </PageContainer>
  
    </div>
  );
}
