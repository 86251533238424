import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#191919",
    },
    secondary: {
      main: "#EB3B5A",
    },
    green: {
      main: "#53B0AE",
    },
    white: {
      main: "#ffffff",
    },
  },
});

export default theme;
