// imports the React Javascript Library
import React from "react";
//Card
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
//Tabs
import { withStyles } from "@mui/styles";
import axios from 'axios'
import {useState} from 'react';

const styles = theme => ({
  root: {
    
    display: "flex",
    
    alignItems: "flex-end"
  },
  input: {
    display: "none"
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  Button: {
    color: '#53B0AE',
  },
});

class ImageUploadCard extends React.Component {
  state = {
    mainState: "initial", // initial
    imageUploaded: 0,
    selectedFile: "",
    url: ""
  };

  handleUploadClick = event => {
    console.log();
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function(e) {
      this.setState({
        selectedFile: [reader.result]
      });
    }.bind(this);
    // this.props.parentCallback("333");
    // event.preventDefault();
    // todo: upload image file
    const postData = async () => {
        try {
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            const response = await axios.post("https://www.digginhk.com/Diggin_hong_kong_backend/DigginHK/event/uploadPaySlip.php", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            // console.log(response);
            // console.log(response.data.success);
            // console.log(response.data.url);
            await this.props.parentCallback("221");
            if(response.data.success) {
                // console.log("success");
                await this.props.parentCallback(response.data.url);
                this.setState({
                    mainState: "uploaded",
                    selectedFile: event.target.files[0],
                    imageUploaded: 1,
                    url: response.data.url
                  });
                  // Call the parent callback function
            } else {
                //todo: alert: please select and upload again
            }

        } catch (error) {
            // Handle errors
            // console.log(error);
            //todo: alert: please select and upload again
        }
        };
    postData();
      
  };

  renderInitialState() {
    const { classes, theme } = this.props;
    const { value } = this.state;

    return (
        <div>
        <Card className={this.props.cardName}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <img
            width="100%"
            className={classes.img}
            src={this.state.selectedFile}
          />
        </Grid>
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span">
          選擇圖片 Select Image
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.handleUploadClick}
            />
          </Button>
        </label>
      </Grid>
      </Card>
     
      </div>
    );
  }

  renderUploadedState() {
    const { classes, theme } = this.props;

    return (
        <Card className={this.props.cardName}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <img
            width="100%"
            className={classes.img}
            src={this.state.selectedFile}
          />
        </Grid>
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span">
            選擇圖片
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.handleUploadClick}
            />
          </Button>
        </label>
      </Grid>
       </Card>
    );
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
          {(this.state.mainState == "initial" && this.renderInitialState()) ||
            (this.state.mainState == "uploaded" && this.renderUploadedState())}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);