import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Grid, Divider } from '@mui/material';

function NewlineText(props) {
  const text = props.text;
  const newText = text.split('\n').map(str => <p>{str}</p>);

  return newText;
}

const useStyles = makeStyles({
  root: {
    minWidth: 150,
  },
});

const EventDetailsCard = props => {
  const classes = useStyles();
  const { eventName, startTime, endTime, eventAddress, applyMethod, eventDetails, eventImageUrl } = props;

  return(
    <div style={{marginTop: 80, marginBottom: 160}}>

      <Card className={classes.root}>
          <CardMedia
            component="img"
            alt={eventName}
            image={eventImageUrl}
            title={eventName}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" color="black">
              {eventName}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
                {startTime.substring(0, startTime.length-3)} - {endTime.substring(0, endTime.length-3)}
            </Typography>

            <p></p>

              <Typography variant="body2" color="textSecondary" component="p">
                  {eventAddress}
              </Typography>
              <p></p>
              <Divider />
              <p></p>
                <Typography variant="body2" color="primary" component="p">
                    報名方式:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <NewlineText text={applyMethod} />
                </Typography>
                <br />
                <Typography variant="body2" color="primary" component="p">
                    詳情:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <NewlineText text={eventDetails} />

                </Typography>
          </CardContent>

      </Card>

    </div>
  );
}

export default EventDetailsCard;
