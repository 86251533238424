import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../public/assets/locales/en/translation.json';
import translationZHTW from '../public/assets/locales/zh-TW/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  zh_tw: {
    translation: translationZHTW
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: ['en', 'zh_tw'],
    fallbackLng: "en",
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    }
  });
export default i18n;
