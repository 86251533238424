import React, { Component } from "react";
import {
  Box,
  Typography,
  Toolbar,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Slider,
  Chip,
  Card,
  CardContent,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import banner from "../images/banner.png";

const useStyles = makeStyles(() => ({
  banner_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 1), rgba(255,255,255, 0.1)), url(${banner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "white",
  },
  banner_center: {
    textAlign: "center",
    margin: "100px",
  },
  card_wrapper: {
    marginBottom: "20px",
  },
  sl_card_diggin: {
    border: "1.5px solid #53B0AE",
    borderRadius: "3px",
    padding: "5px",
    background: "white",
    color: "#53B0AE",
  },
}));

function Banner() {
  const styles = useStyles();

  return (
    <Box className={styles.banner_wrapper}>
      <Box className={styles.banner_center}>
        <Typography variant="h4">所有舞室</Typography>
        <Box sx={{ height: "10px" }} />
        <Typography variant="body1">
          Diggin’ 有超過50間場地可供選擇，
        </Typography>
        <Typography variant="body1">
          所有場地都由Diggin’ 團隊精⼼挑選，偏佈全港各區
        </Typography>
      </Box>
    </Box>
  );
}

function StudioCard(props) {
  const styles = useStyles();

  return (
    <Card className={styles.card_wrapper}>
      <CardContent>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Typography variant="body1">{props.name}</Typography>
          <Box
            className={styles.sl_card_diggin}
            sx={{ display: props.diggin === 1 ? "flex" : "none" }}
          >
            <Typography variant="body2">Diggin’推介</Typography>
          </Box>
        </Box>
        <Typography variant="body2">{props.at}</Typography>
        <Typography variant="body2">{props.location}</Typography>
        <Typography variant="body2">{props.size}尺</Typography>
        <Typography variant="body2">{props.capacity}⼈或以下</Typography>
      </CardContent>
    </Card>
  );
}

const useStyles1 = (theme) => ({
  sl_body_wrapper: {
    display: "grid",
    padding: "20px 50px",
  },
  sl_flex: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  sl_chip_wrapper: {
    width: "100%",
    marginLeft: "20px",
    display: "flex",
    gap: "30px",
  },
  sl_checkbox_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  sl_diggin_checkbox: {
    border: "1.5px solid #53B0AE",
    borderRadius: "3px",
    padding: "5px",
    background: "white",
    color: "#53B0AE",
  },
  sl_filter_wrapper: {
    display: "flex",
    width: "400px",
    height: "fit-content",
  },
});

class StudioList extends Component {
  state = {
    open: [false, false, false, false, false],
    studioList: [
      { id: 1, name: "所有舞室" },
      { id: 2, name: "街舞舞室" },
      { id: 3, name: "鋼管舞室" },
    ],
    siteSizeList: [
      { id: 1, name: "所有⼤⼩" },
      { id: 2, name: "100尺以下" },
      { id: 3, name: "100-500尺" },
      { id: 4, name: "500-1000尺" },
      { id: 5, name: "1000-1500尺" },
      { id: 6, name: "1500尺以上" },
    ],
    facilitiesList: [
      { id: 1, name: "沒有偏好" },
      { id: 2, name: "冷氣" },
      { id: 3, name: "鏡" },
      { id: 4, name: "⾳響" },
      { id: 5, name: "燈光" },
      { id: 6, name: "瑜珈磚" },
    ],
    allStudioList: [
      {
        id: 1,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 2,
        name: "Room A",
        diggin: 0,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 3,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 4,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 5,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 6,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 7,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 8,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 9,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
      {
        id: 10,
        name: "Room A",
        diggin: 1,
        at: "At Dance",
        location: "觀塘道316-318號志聯⼯廠⼤廈",
        size: "650",
        capacity: "20",
      },
    ],
  };

  handleClick = (i) => {
    this.setState((state) => {
      const list = state.open.map((item, j) => {
        if (j === i) {
          return !item;
        } else {
          return item;
        }
      });

      return {
        open: list,
      };
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Box sx={{ background: "#F5F5F5" }}>
        <Toolbar />
        <Banner />
        <Box className={classes.sl_body_wrapper}>
          <Box className={classes.sl_flex}>
            <Box sx={{ width: "400px" }}>
              <Typography variant="h4">所有舞室</Typography>
            </Box>
            <Box className={classes.sl_chip_wrapper}>
              {this.state.studioList.map((studio) => {
                return (
                  <Chip
                    key={studio.id}
                    label={studio.name}
                    sx={{ marginLeft: "10px" }}
                    variant="outlined"
                  />
                );
              })}
            </Box>
          </Box>
          <Box sx={{ display: "flex", margin: "20px 0px 20px 0px" }}>
            <Box className={classes.sl_filter_wrapper}>
              <Box sx={{ display: "grid", gap: "20px", width: "100%" }}>
                <Box sx={{ display: "grid", gap: "10px" }}>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    推介
                  </Typography>
                  <Box className={classes.sl_checkbox_wrapper}>
                    <Box className={classes.sl_diggin_checkbox}>
                      <Typography variant="body2">Diggin’推介</Typography>
                    </Box>
                    <Checkbox />
                  </Box>
                </Box>
                <Divider />
                <Box sx={{ display: "grid" }}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                      地區
                    </Typography>
                  </Box>
                  <Box className={classes.sl_checkbox_wrapper}>
                    <Typography variant="body2">所有地區</Typography>
                    <Checkbox />
                  </Box>
                  <List>
                    <ListItem button onClick={() => this.handleClick(0)}>
                      <ListItemText primary="香港島" />
                      {this.state.open[0] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={this.state.open[0]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem button>
                          <ListItemText primary="Starred" />
                        </ListItem>
                      </List>
                    </Collapse>
                    <ListItem button onClick={() => this.handleClick(1)}>
                      <ListItemText primary="九龍" />
                      {this.state.open[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={this.state.open[1]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem button>
                          <ListItemText primary="Starred" />
                        </ListItem>
                      </List>
                    </Collapse>
                    <ListItem button onClick={() => this.handleClick(2)}>
                      <ListItemText primary="新界" />
                      {this.state.open[2] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={this.state.open[2]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem button>
                          <ListItemText primary="Starred" />
                        </ListItem>
                      </List>
                    </Collapse>
                    <ListItem button onClick={() => this.handleClick(3)}>
                      <ListItemText primary="離島" />
                      {this.state.open[3] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={this.state.open[3]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem button>
                          <ListItemText primary="Starred" />
                        </ListItem>
                      </List>
                    </Collapse>
                  </List>
                </Box>
                <Divider />
                <Box sx={{ display: "grid", gap: "10px" }}>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    可容納⼈數
                  </Typography>
                  <Box sx={{ padding: "10px 20px 10px 5px" }}>
                    <Slider />
                  </Box>
                  <Typography variant="body2">可容納10⼈</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: "grid", gap: "10px" }}>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    場地⼤⼩
                  </Typography>
                  <Box>
                    {this.state.siteSizeList.map((size) => {
                      return (
                        <Box
                          className={classes.sl_checkbox_wrapper}
                          key={size.id}
                        >
                          <Typography variant="body2">{size.name}</Typography>
                          <Checkbox />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Divider />
                <Box>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    設施（可選多項）
                  </Typography>
                  <Box>
                    {this.state.facilitiesList.map((facility) => {
                      return (
                        <Box
                          className={classes.sl_checkbox_wrapper}
                          key={facility.id}
                        >
                          <Typography variant="body2">
                            {facility.name}
                          </Typography>
                          <Checkbox />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Divider />
              </Box>
              <Box>
                <Divider orientation="vertical" />
              </Box>
            </Box>
            <Box sx={{ width: "100%", marginLeft: "20px" }}>
              <Box>
                {this.state.allStudioList.map((studios) => {
                  return (
                    <StudioCard
                      key={studios.id}
                      name={studios.name}
                      diggin={studios.diggin}
                      at={studios.at}
                      location={studios.location}
                      size={studios.size}
                      capacity={studios.capacity}
                    />
                  );
                })}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "right" }}>
                <Pagination count={10} variant="outlined" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(useStyles1)(StudioList);
