import React from 'react'
import { makeStyles } from '@mui/styles';
import EventContent from './EventContent';
import ImageList from '@mui/material/ImageList';

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: 'nowrap',
    margin: "0px !important",
    transform: 'translateZ(0)',
    width: "100%",
    height: "100%"
  }
}));

function EventHomepage () {
  const classes = useStyles();
    return (
     <ImageList className={classes.gridList} >
        <EventContent />
     </ImageList>
    );
  };

export default EventHomepage;
