import React, { Component } from 'react'
import EventCard from './EventCard';
import { Grid } from "@mui/material";
import eventsList from './constants';
import axios from 'axios'
import "../../../App.css";

const getEventCard = eventObj => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <EventCard {...eventObj} />
    </Grid>
  );
};

class Content extends Component {

  constructor() {
  super();
  }

  state = {
    events: [],
    isLoaded: false,
  }

  componentDidMount() {
      var instance = axios.create({ baseURL: 'https://www.digginhk.com' });
      instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/get/?name=eventsList&countryId='+this.props.match.params.countryId+'&districtCatId='+this.props.match.params.districtCatId+'&districtId='+this.props.match.params.districtId, {
      crossDomain: true
    })
      .then(res => {
        this.setState({
          events: res.data,
          isLoaded: true,
        });
      });
  }

  render() {
    var { isLoaded, events } = this.state;
    if (!isLoaded) {
      return (
        <div></div>
      );
    } else {

      return (
        <Grid container spacing={10}>
          {events.events.map(eventObj => getEventCard(eventObj))}
        </Grid>

      );
    }
  };
};

export default Content;
