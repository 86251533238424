import React from 'react'
import { makeStyles } from '@mui/styles';
import ProductList from './ProductList'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ImageList, Container } from '@mui/material';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    height: "430px !important",
    width: "100%",
    overflow: "hidden",
  },
  imageList:{
    flexWrap: 'nowrap',
    height: '410px',
    overflow: 'hidden',
    margin: "0px 0px 0px 2px !important",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },

  gridList: {
    height: "400px !important",
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    margin: "0px !important",
    padding: "0px !important", 
  },

  card: {
    margin:"0px 50px 0px 2px", 
    height: "395px !important", 
    width: "300px !important" , 
    padding: "0px !important",
    '&:hover': {
      border:'2px solid #53B0AE',
      background: "#FFF",
    },
  }
}));

function EcommerceCard() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ImageList className={classes.imageList}>
        {ProductList.map((ProductList) => (
          <ImageList className={classes.gridList}>
            <Card className={classes.card}>
              <CardActionArea style={{height: "100%"}}>
                <CardMedia 
                  component="img"
                  alt={ProductList.ProductName}
                  height="300"
                  width="200"
                  image={ProductList.Image}
                  title={ProductList.ProductName}  
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="h2" style={{fontWeight: "bold"}}>
                    {ProductList.ProductName}
                  </Typography>
                  <p></p>
                  <Typography variant="caption" color="textSecondary" component="p">
                    <div style={{display: "flex", alignItems: "center"}}>
                      <MonetizationOnOutlinedIcon style={{fontSize:"medium"}}/> <Container style={{padding: "1px 0px 0px 10px"}}> {ProductList.ProductPrice} </Container>
                    </div>
                  </Typography>
                </CardContent>        
              </CardActionArea>  
            </Card>
          </ImageList>
        ))}
      </ImageList>
    </div>
   
  );
}
export default EcommerceCard;
