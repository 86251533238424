import React from "react";
import styled from "styled-components";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
import { Navbar } from "../Navbar";
import HomepageFooter from '../Footers/HomepageFooter';

export const TermsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  background-color: #fff;
  margin-left: 100px;
  margin-right: 100px;
  white-space: pre-line;
`;

export const TermsMobileContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  white-space: pre-line;
`;

const TitleText = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

export function TutorialsTerms(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  return (
    <div>
    <Navbar />
    <div style={{marginTop: 120}}/>
    <TitleText>{t('terms_of_use')}</TitleText>
    <div style={{marginTop: 40}}/>
    {isMobile && <TermsMobileContainer>
      {t('tutorials_terms_of_use_content')}

    </TermsMobileContainer>
    }
    {!isMobile && <TermsContainer>
      {t('tutorials_terms_of_use_content')}
    </TermsContainer>
    }
    <div style={{marginBottom: 100}}/>
    <HomepageFooter />
    </div>
  );
}
