import React, { Component } from 'react'
import { Grid } from "@mui/material";
import eventsList from '../Event/constants';
import axios from 'axios'
import "../../../App.css";
import styled from "styled-components";
import {Link} from 'react-router-dom';
import '../../../i18n';
import { withTranslation } from "react-i18next";

// import MaterialTable from "material-table";
import { DataGrid } from '@mui/x-data-grid';
import tableIcons from "../MaterialTable/MaterialTableIcons";

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;


class EventCategoryRecordsData extends Component {

  constructor() {
  super();
  }

  state = {
    result: [],
    mark_details: [],
    isLoaded: false,
    mode: "0",
  }

  componentDidMount() {
      var instance = axios.create({ baseURL: 'https://www.digginhk.com' });
      instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=getEventRecords&categoryId='+this.props.match.params.category_id+'&mode='+this.props.match.params.selection_mode, {
      crossDomain: true
    })
      .then(res => {
        const mark_details = [];
        if (this.props.match.params.selection_mode == "7") {  //for shortcut prelim
          for (let i = 0; i < res.data.applications.length; i++) {
              mark_details.push({
                  name: res.data.applications[i].name,
                  remark: res.data.applications[i].remark,
                  draw_num: res.data.applications[i].draw_num,
                  total_mark: res.data.applications[i].total_mark,
                  total_mark_without_headtail: res.data.applications[i].total_mark_without_headtail,
                  judges: res.data.applications[i].judges,
              });
          };
        }
        if (this.props.match.params.selection_mode == "6") {  //for shortcut red blue
          for (let i = 0; i < res.data.matches.length; i++) {
              mark_details.push({
                  red_side: res.data.matches[i].red_team,
                  blue_side: res.data.matches[i].blue_team,
                  match_order: res.data.matches[i].match_order,
                  match_winner: res.data.matches[i].match_winner,
                  rounds: res.data.matches[i].rounds,
              });

          };
        }

        if (this.props.match.params.selection_mode == "0") {  //for normal judging
          for (let i = 0; i < res.data.applications.length; i++) {
              mark_details.push({
                  name: res.data.applications[i].name,
                  draw_num: res.data.applications[i].draw_no,
                  total_mark: res.data.applications[i].total_mark,
                  judges: res.data.applications[i].judges,
              });

          };
        }
        this.setState({
          mode: this.props.match.params.selection_mode,
          result: res.data,
          mark_details: mark_details,
          isLoaded: true,
        });
      });
  }

  render() {
    const { t } = this.props;
    const message = t("name");
    const mode0columns =  [
      { title: t("name"), field: "name" },
      { title: t("draw_num"), field: "draw_num", type: "numeric" },
      { title: t("total_mark"), field: "total_mark", type: "numeric" },
    ]
    const mode6columns =  [
      { title: t("red_side"), field: "red_side" },
      { title: t("blue_side"), field: "blue_side" },
      { title: t("match_order"), field: "match_order", type: "numeric" },
      { title: t("match_winner"), field: "match_winner" },
    ]
    const mode7columns =  [
      { title: t("name"), field: "name" },
      { title: t("number"), field: "remark" },
      { title: t("draw_num"), field: "draw_num", type: "numeric" },
      { title: t("total_mark"), field: "total_mark", type: "numeric" },
      { title: t("total_mark_without_headtail"), field: "total_mark_without_headtail", type: "numeric" },
    ]
    var { isLoaded, result, mark_details, mode } = this.state;
    if (!isLoaded) {
      return (
        <div>Loading..</div>
      );
    } else {

      if (mode == "7") {  //prelim, shortcut
        return (
          <div>
          <TitleText>{result.categoryName}</TitleText>
            <div style={{marginTop: 40}}/>
            <DataGrid style={{ zIndex: 0 }}
            title={t('score_result')} icons={tableIcons} columns={mode7columns} data={mark_details} options={{
                  exportButton: true,
              }}
              detailPanel={rowData => {
                      return (
                        <div style={{marginLeft: 70, marginRight: 70}}>
                        <br/>
                        {rowData.judges.map((judge, i) =>
                          <div>
                          <div>{judge.judge_name} - mark: {judge.total_mark}</div>
                          {judge.criteria_list.map((criteria, m) =>
                            <>{criteria.criteria_name}: {criteria.calculated_mark} </>
                          )}
                          {judge.shortcuts.map((shortcut, k) =>
                            <div>{shortcut} </div>
                          )}
                          </div>
                        )}
                        <br/>
                        </div>
                      )
                    }}
                    />
            <div style={{marginTop: 40}}/>
          </div>

        );
      } else if (mode == "6") {  //red blue, shortcut
        return (
          <div>
          <TitleText>{result.categoryName}</TitleText>
            <div style={{marginTop: 40}}/>
            <DataGrid style={{ zIndex: 0 }}
            title={t('score_result')} icons={tableIcons} columns={mode6columns} data={mark_details} options={{
                  exportButton: true,
              }}
              detailPanel={rowData => {
                      return (
                        <div style={{marginLeft: 70, marginRight: 70}}>
                        <br/>
                        {rowData.rounds.map((round, i) =>
                          <div>
                          <div>{t('round')} {round.round_num} {t('winner')}: {round.round_winner}</div>

                          <div>{rowData.red_side} - {t('mark')}</div>
                          <div>{t('total_mark')}, {t('total_mark_without_headtail')}: {round.red_total_mark}, {round.red_total_mark_without_headtail}</div>
                          {round.red_judges.map((red_judge, k) =>
                            <div>
                            <div>{red_judge.judge_name} - {t('mark')}: {red_judge.total_mark}</div>
                            {red_judge.criteria_list.map((criteria, m) =>
                              <>{criteria.criteria_name}: {criteria.calculated_mark} </>
                            )}
                            {red_judge.shortcuts.map((shortcut, m) =>
                              <div>{shortcut} </div>
                            )}
                            </div>
                          )}
                          <br/>
                          <div>{rowData.blue_side} - {t('mark')}</div>
                          <div>{t('total_mark')}, {t('total_mark_without_headtail')}: {round.blue_total_mark}, {round.blue_total_mark_without_headtail}</div>
                          {round.blue_judges.map((blue_judge, k) =>
                            <div>
                            <div>{blue_judge.judge_name} - {t('mark')}: {blue_judge.total_mark}</div>
                            {blue_judge.criteria_list.map((criteria, m) =>
                              <>{criteria.criteria_name}: {criteria.calculated_mark} </>
                            )}
                            {blue_judge.shortcuts.map((shortcut, m) =>
                              <div>{shortcut} </div>
                            )}
                            </div>
                          )}
                          <br/><br/>
                          </div>
                        )}


                        </div>
                      )
                    }}
              />
            <div style={{marginTop: 40}}/>

          </div>

        );
      } else if (mode == "0") {  //normal judging
        return (
          <div>
          <TitleText>{result.categoryName}</TitleText>
            <div style={{marginTop: 40}}/>
            <DataGrid style={{ zIndex: 0 }}
            title={t('score_result')} icons={tableIcons} columns={mode0columns} data={mark_details} options={{
                  exportButton: true
              }}
              detailPanel={rowData => {
                      return (
                        <div style={{marginLeft: 70, marginRight: 70}}>
                        <br/>
                        {rowData.judges.map((judge, i) =>
                          <div>
                          <div>{judge.judge_name} - {t('mark')}: {judge.mark}</div>
                          </div>
                        )}
                        <br/>
                        </div>
                      )
                    }}
                    />
            <div style={{marginTop: 40}}/>
          </div>

        );
      } else {
        return (
          <div></div>
        );
      }
    }
  };
};

export default withTranslation()(EventCategoryRecordsData);
