import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
import { Navbar } from "../Navbar";
import HomepageFooter from '../Footers/HomepageFooter';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-right: 80px;
  padding: 0 1.5em;
  background-color: #fff;
  white-space: pre-line;
  fontFamily: "Noto Sans",
`;

const TitleText = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;
const ContentText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

export function EventApplicationSuccess(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const { event_public_id, categoryId } = useParams();
  // console.log('Event Public ID:', event_public_id);
  // console.log('Event event_category_id ID:', categoryId);

  const [state, setState] = useState({
    isLoaded: false,
    categoryName: '',
    eventName: '',
    teamName: '',
    refNo: '',
    enrolled_result: '',
  });

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const token = localStorage.getItem('token');
        const instance = axios.create({
          baseURL: 'https://www.digginhk.com',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
       
        const res = await instance.get(
          `/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=eventApplicationSuccessData&pid=${event_public_id}&cid=${categoryId}`,
          {
            crossDomain: true,
          }
        );
        
        if (res.data.status == 200) {

          if (res.data.categoryName == "") {
            setState((prevState) => ({
              ...prevState,
              isLoaded: false,
              categoryName: res.data.categoryName,
              eventName: res.data.eventName,
              teamName: res.data.teamName,
              refNo: res.data.refNo,
              enrolled_result: res.data.enrolled_result,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              isLoaded: true,
              categoryName: res.data.categoryName,
              eventName: res.data.eventName,
              teamName: res.data.teamName,
              refNo: res.data.refNo,
              enrolled_result: res.data.enrolled_result,
            }));
          }

        } else {
          
          setState((prevState) => ({
            ...prevState,
            isLoaded: true,
          }));
        }
      } catch (error) {
        
        console.error(error);
      }
    };

    fetchEventData();
  }, [event_public_id, categoryId]);

  const token = localStorage.getItem('token');
  if (!state.isLoaded || !token) {
    return <div><Navbar /><div style={{marginBottom: 100}}/>
    <HomepageFooter /></div>;
  } else {
    return (
      <div>
      <Navbar />
      <div style={{marginTop: 120}}/>
      <TitleText>{t('event_apply_success')}</TitleText>
      <div style={{marginTop: 40}}/>
      <PageContainer>
          <ContentText>{t('event_apply_success_content')}</ContentText>
          <div style={{marginTop: 20}}/>
          <ContentText><QRCode value={state.refNo} /></ContentText>
          <div style={{marginTop: 20}}/>
          <ContentText>
          {t('event')}: {state.eventName ? state.eventName : '-'}
        </ContentText>
        <div style={{marginTop: 20}}/>
          <ContentText>
          {t('category')}: {state.categoryName ? state.categoryName : '-'}
        </ContentText>
        
        {state.teamName ? (<div style={{marginTop: 20}}><ContentText>
          {t('teamname')}: {state.teamName ? state.teamName : '-'}
        </ContentText></div>):null}
        <div style={{marginTop: 20}}/>
          <ContentText>
          {t('reference_no')}: {state.refNo ? state.refNo : '-'}
        </ContentText>
      </PageContainer>

      <div style={{marginBottom: 100}}/>
      <HomepageFooter />
      </div>
    );
  }
  

}
