import React from "react";
import styled from "styled-components";
import i18n from '../../../i18n';
import { Navbar } from "../Navbar";
import HomepageFooter from '../Footers/HomepageFooter';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
import { Container, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RecordsList from './RecordsList';
import EventCategoryRecordsData from './EventCategoryRecordsData';

export const EventCategoryRecordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  padding: 0 1.5em;
  background-color: #fff;
  white-space: pre-line;
  fontFamily: "Noto Sans",

  a:link {
    text-decoration: none;
    color: #191919;
  }

  a:visited {
    text-decoration: none;
    color: #191919;
  }

  a:hover {
    text-decoration: none;
    color: #000;
  }

  a:active {
    text-decoration: none;
  }
`;

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const SubTitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const ContentText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const FormButton = styled.button`
  border: 0;
  outline: 0;
  padding: 8px 1em;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 18px;
  background-color: #53B0AE;
  cursor: pointer;
`;

const useStyles = makeStyles({
  a: {
    display: 'block',
    height: '100%',
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});

export function EventCategoryRecords(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 992px)'
  })
  return (
    <div>
      <Navbar />
        <div style={{marginTop: 120}}/>
        <EventCategoryRecordsContainer>
          <EventCategoryRecordsData {...props}/>
        </EventCategoryRecordsContainer>
        <div style={{marginBottom: 100}}/>
      <HomepageFooter />
    </div>


  );
}
