import React from "react";
import styled from "styled-components";
import i18n from '../../../../i18n';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive'
import EventApplicationFormDesktop from "./EventApplicationFormDesktop";
import EventApplicationFormMobile from "./EventApplicationFormMobile";


export function EventApplicationFormBody(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 992px)'
  })
  return (
    <div>
    {!isMobile && <EventApplicationFormDesktop {...props}/>}
    {isMobile && <EventApplicationFormMobile {...props}/>}
    </div>
  );
}
