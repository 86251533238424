import React from 'react'
import { makeStyles } from "@mui/styles";
import { Typography, Link } from "@mui/material";
import facebook_logo from '../images/facebook_logo.png';
import instagram_logo from '../images/instagram_logo.png';
import whatsapp_logo from '../images/whatsapp_logo.png';
import email_logo from '../images/email_logo.png';
import location_logo from '../images/location_logo.png';
import linkedin_logo from '../images/linkedin_logo.png';
import iOS_download from '../images/iOS_download.png';
import android_download from '../images/android_download.png';
import qrcode from '../images/qrcode.png'
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";

var style = {
  backgroundColor: "black",
  position: "relative",
  padding: "65px 100px",
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Noto Sans",
},

phantom = {
  display: 'block',
}

const useStyles = makeStyles(() => ({
  whiteColor: {
    color: '#ffffff',
    fontSize: 15,
    position: "relative",
    padding: "0px 0px 20px 0px",
    fontFamily: "Noto Sans",
  },
  address:{
    color: '#ffffff',
    fontSize: 15,
    float: "right",
    fontFamily: "Noto Sans",
  },
  button: {
    color: '#ffffff',
    padding: '10px',
    fontFamily: "Noto Sans",
  }
}));

export function HomepageDesktopFooter(props) {
    const { t } = useTranslation();
    const classes = useStyles();
      return (
        <div>
          <div style={phantom} />
            <div style={style}>
              <div style={{ height: "fit-content"}}>
                <a target="_blank"  href="https://www.instagram.com/digginmobileofficial/"><img src={instagram_logo} style={{width:"32px" , height:"32px", padding:"0px 40px 0px 0px"}}/></a>
                <a target="_blank" href="https://www.facebook.com/DigginOfficial"><img src={facebook_logo} style={{width:"32px" , height:"32px", padding:"0px 40px 0px 0px"}}/></a>
                <a target="_blank" href="mailto:contact@diggin.ltd"><img src={email_logo} style={{width:"32px" , height:"32px", padding:"0px 40px 0px 0px"}}/></a>
                <a target="_blank" href="https://wa.me/85298503893"><img src={whatsapp_logo} style={{width:"32px", height:"32px", padding:"0px 40px 0px 0px"}} /></a>
                <a target="_blank" href="https://maps.app.goo.gl/tew46SqYLugUoDkd8"><img src={location_logo} style={{width:"32px", height:"32px", padding:"0px 40px 0px 0px"}} /></a>
                <br/><br/>
                <Typography className={classes.whiteColor}>
                <a style={{color: "#fff", textDecoration: 'none'}} href="/about_us">{t('about_us')}</a> | <a style={{color: "#fff", textDecoration: 'none'}} href="/clients">{t('clients')}</a>
                <br/><br/>
                <a style={{color: "#fff", textDecoration: 'none'}} href="/terms">{t('terms_of_use')}</a> | <a style={{color: "#fff", textDecoration: 'none'}} href="/privacy">{t('privacy')}</a>
                </Typography>
                <Typography className={classes.whiteColor}>
                {t('copyright')}

                </Typography>
              </div>

              <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", padding: "10px 0px"}}>
                <img src={qrcode} style={{ padding:"0px 24px 0px 0px", height:"72px", width:"72px"}} />
                  <div style={{display: "grid"}}>
                    <a target="_blank" href="https://apps.apple.com/app/diggin-dig-it-dance-it/id1123086521"><img src={iOS_download} height="32" width="108"/> </a> <a target="_blank" href="https://play.google.com/store/apps/details?id=com.lcproducts.digginghk" style={{padding: "5px 0px"}}><img src={android_download} height="32" width="108"/></a>
                  </div>
              </div>

            </div>

        </div>
    );
}
