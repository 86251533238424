import React, { Component } from 'react'
import HomepageEventCard from './HomepageEventCard';
import { Container, ImageList } from "@mui/material";
import axios from 'axios'
// import ImageList from '@mui/material/ImageList';

var container = {
  display:'inline-block',
  flex:'nowrap',
  marginLeft: '0',
  paddingLeft: '0',
  maxWidth: '200px'
},

root = {
  display: 'inline-block',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  overflow: 'hidden',
  height: "100%"
},

gridList = {
  flexWrap: 'nowrap',
  height: '275px',
  width: '400px',
  overflow: 'hidden',
  margin: "0px !important"

},

imageList = {
  flexWrap: 'nowrap',
  height: '285px',
  overflow: 'hidden',
  margin: "0px !important"
}

const getEventCard = eventObj => {
  return (
      // <ImageList style={gridList} >
        <HomepageEventCard {...eventObj} />
      // </ImageList>
  );
};

class EventContent extends Component {
  state = {
    events: [],
    isLoaded: false,
  }

  constructor() {
    super();
    var instance = axios.create({ baseURL: 'https://www.digginhk.com' });
    instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=homeEventsList&cid=1')
    .then(res => {
      this.setState({
        events: res.data,
        isLoaded: true,
      });
    });
  }

  render() {
    var { isLoaded, events } = this.state;
    if (!isLoaded) {
      return (
        <div></div>
      );
    } else {
      return (
        <Container style={container}>
          <div style={root}>
            <ImageList variant="fullWidth" cols={5} gap={8} style={imageList} >
              {events.events.map(eventObj => getEventCard(eventObj))}
            </ImageList>
          </div>
        </Container>
      );
    }
  };
};

export default EventContent;
