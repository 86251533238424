import React, { Component } from 'react'
import axios from 'axios'
import { Grid } from '@mui/material';
import EventDetailsCard from './EventDetailsCard';
import Typography from '@mui/material/Typography';
import { Navbar } from '../Navbar';
import HomepageFooter from '../Footers/HomepageFooter';
import "../../../App.css";
import '../../../i18n';
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Container, Link } from '@mui/material';


var style = {
  color: '#53B0AE',
  fontSize: 22,
  textAlign: "center",
}

var img = {
  objectFit: 'contain',
  width: '100%'
}

export const EventPageContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 0 1.5em;
  background-color: #fff;
  margin-left: 100px;
  margin-right: 100px;
  white-space: pre-line;
`;

export const LeftSection = styled.div`
  display: flex;
  flex: 1;
  margin-right: 32px;

  flex-direction: column;
`;

export const RightSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  a:link {
    text-decoration: none;
    color: #191919;
  }

  a:visited {
    text-decoration: none;
    color: #191919;
  }

  a:hover {
    text-decoration: none;
    color: #53B0AE;
  }

  a:active {
    text-decoration: none;
  }
`;

const TitleText = styled.div`
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const SubTitleText = styled.div`
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const ContentText = styled.div`
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;

  
  fontFamily: "Noto Sans",
`;

const FormButton = styled.button`
  border: 1px solid #53B0AE;
  outline: 0;
  padding: 8px 1em;
  color: #53B0AE;
  font-size: 14px;
  font-weight: 300;
  font-width: 300px;
  border-radius: 18px;
  background-color: #fff;
  cursor: pointer;
`;

const getEventCategoryTitle = (event_public_id,categoryObj,t) => {
  return(
    
    <Grid item xs={12} sm={6} md={4}>
      {
        categoryObj.price === "0" ? (
          <a
            style={{ marginRight: 12 }}
            href={"/event_apply/" + event_public_id + "/" + categoryObj.categoryId}
          >
            <FormButton>
              <b>{categoryObj.categoryName}</b>
              <br />
              {t('free_register')}
            </FormButton>
          </a>
        ) : (
          <a
            style={{ marginRight: 12 }}
            href={"/event_apply/" + event_public_id + "/" + categoryObj.categoryId}
          >
            <FormButton>
              <b>
                {categoryObj.categoryName}
                <br />
                {categoryObj.currency}
                {categoryObj.price}
              </b>
            </FormButton>
          </a>
        )
      }
    </Grid>
  );
};

class EventPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      eventObj: [],
      eventCategories: [],
      eventImageUrl: "",
      event_public_id: "0",
      isLoaded: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.newData !== this.props.newData) {
      this.setState({ event_public_id: this.props.event_public_id, })
    }
  }

  componentDidMount() {
    this.setState({
      event_public_id: this.props.match.params.event_public_id,
    });
    var instance = axios.create({ baseURL: 'https://www.digginhk.com' });
    instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/get/?name=eventByPid&eventPId='+this.props.match.params.event_public_id, {
    crossDomain: true
    })
    .then(res => {
      this.setState({
        eventObj: res.data.event,
        eventImageUrl: res.data.imageUrl,
        event_public_id: this.props.match.params.event_public_id,
      },
      () =>
          instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=getEventCateories&pid='+this.props.match.params.event_public_id, {
          crossDomain: true
          })
          .then((res) => {
            this.setState({
              eventCategories: res.data.categories,
              isLoaded: true,
            })
          })
      );
    });
  }


  render() {
    const { t } = this.props;
    var { isLoaded, event_public_id, eventObj, eventImageUrl, eventCategories } = this.state;

    if (!isLoaded) {
      return (
        <div></div>
      );
    } else {
      if (this.state.eventObj) {

        return (
          <EventPageContainer>

          <LeftSection>
          <img
            style={img}
            srcSet={eventImageUrl}
            alt="Event Image"
          />

          </LeftSection>

          <RightSection>

          <TitleText>{eventObj.eventName}</TitleText>
          <br/><br/>

          <SubTitleText>
            <div style={{display:"flex"}}>
              <AccessTimeIcon style={{fontSize:"large", marginTop: 2}}/>
              <Container style={{padding: "0px 0px 0px 10px"}}>
              {eventObj.startTime.slice(0, -3).replaceAll("-", ".")} - {eventObj.endTime.slice(0, -3).replaceAll("-", ".")}
              </Container>
            </div>
          </SubTitleText>

            <br/>

            <SubTitleText>
              <div style={{display:"flex", justifyContent: "center"}}>
                <RoomOutlinedIcon style={{fontSize:"large", marginTop: 2}}/>
                <Container style={{padding: "0px 0px 0px 10px"}}>
                <a target="_blank" href={'https://maps.google.com/?q='+eventObj.lat+','+eventObj.long}>{eventObj.eventAddress}</a>
                </Container>
              </div>
              </SubTitleText>
              
              <br/>

          <ContentText>
          
          
          <Grid container spacing={4}>
            {/* todo: now only show first category rrr*/}
          {/* getEventCategoryTitle(event_public_id,eventCategories[0])*/}
          {eventCategories.map(categoryObj => getEventCategoryTitle(event_public_id,categoryObj,t))}
          </Grid>

          <br/><br/>
          {t('apply_method')}:
          <br/>
          {eventObj.applyMethod}
          <br/><br/><br/>
          {t('details')}:
          <br/>
          {eventObj.eventDetails}
          </ContentText>
          </RightSection>


          </EventPageContainer>
        );
      } else {
        return (
          <div style={{marginTop: 180, marginBottom: 160}}>
            <Typography style={style}>
              {t('event_not_available')}
            </Typography>
          </div>
        );
      }
    }
  };

}
export default withTranslation()(EventPage);
