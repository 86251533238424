import React, { Component } from 'react'
import '../../../i18n';
import { withTranslation } from "react-i18next";
import { Navbar } from "../Navbar";
import HomepageFooter from '../Footers/HomepageFooter';
import { EventDetailsMain } from "./EventDetailsMain";

class EventDetails extends Component {

  constructor() {
  super();
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Navbar />
          <div style={{marginTop: 120}}/>
          <EventDetailsMain {...this.props}/>
          <div style={{marginBottom: 100}}/>
        <HomepageFooter />
      </div>
    )
  }
}

export default withTranslation()(EventDetails);
