import React, { Component } from 'react'
import { AppBar, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from '../images/digginlogo_purewhite.png';
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { withTranslation } from "react-i18next";

var style = {
    textAlign: "right",
    height: "20px",
    right: "0",
}

var logostyle = {
    textAlign: "left",
    height: "20px",
    right: "0",
}

const useStyles = makeStyles(() => ({
    appbar: {
        position: "absolute",
        top: "0",
        backgroundColor: "transparent"
    },
    toolbar: {
        position: "absolute",
        width: "100%",
        justifyContent: "space-between",
        paddingLeft: "100px !important",
        paddingRight: "100px !important",
    },
    button: {
        fontSize: 14,
        color: 'white',
        textTransform: 'none',
        textDecoration: 'none',
        display: 'inline-block',
        padding: '15px 20px',
        fontFamily: "Calibri",
        fontWeight: "bold",
        '&:hover': {
            borderBottom: '4px solid #53B0AE',
        },
    }
}));

const HomepageHeader = ({ t }) => {
    const classes = useStyles();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    }
    return (
        <AppBar className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
                <a style={logostyle} href="/home" > <img src={logo} width="65" height="30" alt="logo" /></a>
                <div style={style}>
                    <Link to="/share/home" className={classes.button}> {t('home')} </Link>
                    <Link to="/events" className={classes.button}> {t('events')} </Link>
                    <Link to="/about_us" className={classes.button}> {t('about_us')} </Link>
                    <Link to="#" className={classes.button}> {t('clients')} </Link>
                    <Link to="#" className={classes.button}> {t('join_us')} </Link>
                    <button onClick={() => changeLanguage('zh_tw')}>中文</button>
                    <button onClick={() => changeLanguage('en')}>EN</button>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default withTranslation()(HomepageHeader);
