import React from "react";
import styled from "styled-components";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
import { Container } from '@mui/material';
import { RegisterMainMobile } from "./RegisterMainMobile";
import { RegisterMainDesktop } from "./RegisterMainDesktop";

export function RegisterMain(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 992px)'
  })
  return (
    <div>
    {!isMobile && <RegisterMainDesktop />}
    {isMobile && <RegisterMainMobile />}
    </div>
  );
}