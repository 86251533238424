import * as React from 'react';
import { useMediaQuery } from 'react-responsive'
import { Logo } from "../../logo";
import { NavLinks } from "./navLinks";
import { Accessibility } from "./accessibility";
import { NavBarContainer, LeftSection, MiddleSection, RightSection } from "./styles";
import { DeviceSize } from "../../responsive";
import { MobileNavLinks } from "./mobileNavLinks";

export function Navbar() {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  return (
    <NavBarContainer>
      <LeftSection>
        <Logo />
      </LeftSection>
      <MiddleSection>
        {!isMobile && <NavLinks />}
      </MiddleSection>

      <RightSection>
        {/*
        {!isMobile && <Accessibility />}
        */}
        {isMobile && <MobileNavLinks />}
      </RightSection>

    </NavBarContainer>
  );
}
