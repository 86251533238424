import React from "react";
import styled from "styled-components";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";
import logo from '../images/logo512.png';
import diggin_team from '../images/diggin_event_thefirst3.jpg';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMediaQuery } from 'react-responsive'
import { AboutUsMainMobile } from "./AboutUsMainMobile";
import { AboutUsMainDesktop } from "./AboutUsMainDesktop";

export function AboutUsMain(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 992px)'
  })
  return (
    <div>
    {!isMobile && <AboutUsMainDesktop />}
    {isMobile && <AboutUsMainMobile />}
    </div>
  );
}
