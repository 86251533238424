import React, { Component } from 'react'
import '../../../i18n';
import { withTranslation } from "react-i18next";
import { HomeDetails } from "./HomeDetails";
import styled from "styled-components";
import "../../../App.css";

class Home extends Component {

    render() {
       const { t } = this.props;
       return (
          <div>
          <HomeDetails />
          </div>
        )
     }
}

export default withTranslation()(Home);
