import React from 'react';
import {useState,useEffect} from 'react';
import styled from "styled-components";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
`;

const LinkItem = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;

  &:hover {
    border-top: 4px solid #53B0AE;
  }
`;

const SmallLinkItem = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`;



export function NavLinks(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  useEffect(() => {
    // Check for token in local storage on component mount
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    // Add your logout logic here
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  }

  return (
    <NavLinksContainer>
      <LinksWrapper>
        <LinkItem><Link href="/home">{t('home')}</Link></LinkItem>
        <LinkItem><Link href="/events/0/0/0">{t('events')}</Link></LinkItem>
        {/*
          <LinkItem><Link href="/events_records">{t('event_records')}</Link></LinkItem>
        */}
        {/* <LinkItem><Link href="/about_us">{t('about_us')}</Link></LinkItem>
        <LinkItem><Link href="/clients">{t('clients')}</Link></LinkItem> */}
        <LinkItem><Link href="/join_us">{t('join_us')}</Link></LinkItem>
        <LinkItem><Link onClick={() => changeLanguage('zh_tw')}>中文</Link></LinkItem>
        <LinkItem><Link onClick={() => changeLanguage('en')}>EN</Link></LinkItem>
        {isLoggedIn ? (
          <LinkItem><Link onClick={handleLogout}>{t('logout')}</Link></LinkItem>
        ) : (
          <LinkItem><Link href="/login">{t('login')}</Link></LinkItem>
        )}
        
{/*
        <SmallLinkItem onClick={() => changeLanguage('zh_tw')}>中文</SmallLinkItem>
        <SmallLinkItem onClick={() => changeLanguage('en')}>EN</SmallLinkItem>
*/}
      </LinksWrapper>
    </NavLinksContainer>
  );
}
