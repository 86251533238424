import React, { Component } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import EventDetailsCard from "../EventDetailsCard";
import Typography from "@mui/material/Typography";
import { Navbar } from "../../Navbar";
import HomepageFooter from "../../Footers/HomepageFooter";
import "../../../../App.css";
import "../../../../i18n";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { TextField, Container, Link } from "@mui/material";
import ImageUpload from "../../ImageUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../CheckoutForm";

var style = {
  color: "#53B0AE",
  fontSize: 22,
  textAlign: "center",
};

var img = {
  objectFit: "contain",
  width: "100%",
};

export const EventPageContainer = styled.div`
  display: flex;
  padding: 0 1.5em;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  white-space: pre-line;
`;

export const ContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a:link {
    text-decoration: none;
    color: #191919;
  }

  a:visited {
    text-decoration: none;
    color: #191919;
  }

  a:hover {
    text-decoration: none;
    color: #53b0ae;
  }

  a:active {
    text-decoration: none;
  }
`;

const TitleText = styled.div`
  padding: 0 1.1em;
  color: #53b0ae;
  font-weight: bold;
  font-size: 24px;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const SubTitleText = styled.div`
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const ContentText = styled.div`
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;

  fontfamily: "Noto Sans";
`;

const theme = {
  green: {
    default: "#53B0AE",
  },
  pink: {
    default: "#e91e63",
    hover: "#ad1457",
  },
};

const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  border: 0;
  height: 44px;
  width: 100%;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

Button.defaultProps = {
  theme: "green",
};

class EventApplicationFormMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isLoaded2: false,
      eventObj: [],
      category_valid: false,
      event_public_id: "0",
      event_category_id: "0",
      category_name: "",
      category_currency: "",
      category_price: "",
      total_participants: "",
      min_participants: "",
      participants: [], // added participants array
      team_name: "",
      email: "",
      open: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.newData !== this.props.newData) {
      this.setState({ event_public_id: this.props.event_public_id });
    }
  }

  componentDidMount() {
    this.setState({
      event_public_id: this.props.match.params.event_public_id,
      event_category_id: this.props.match.params.categoryId,
    });


    const token = localStorage.getItem('token');

    var instance = axios.create({
      baseURL: 'https://www.digginhk.com',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
   
    instance
      .get(
        "/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=validEventCategoryId&pid=" +
          this.props.match.params.event_public_id +
          "&cid=" +
          this.props.match.params.categoryId,
        {
          crossDomain: true,
        }
      )
      .then((res) => {
        if (res.data.valid) {
          this.setState({
            isLoaded: true,
            valid: true,
            category_name: res.data.category_name,
            category_currency: res.data.currency,
            category_price: res.data.price,
            total_participants: res.data.total_participants,
            min_participants: res.data.min_participants,
            eventObj: res.data.event,
            event_public_id: this.props.match.params.event_public_id,
            participants: Array(res.data.total_participants).fill().map((_, index) => ({
              firstName: "",
              lastName: "",
              nickname: "",
              gender: "",
              contact: "",
            })),
          });
        } else {
          console.log("not valid");
          this.setState({
            isLoaded: true,
            event_public_id: this.props.match.params.event_public_id,
          });
        }
      });

      //check if category is valid
    instance
      .get(
        "/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=checkCategoryApplyStatus&cid=" + 
        this.props.match.params.categoryId,
        {
          crossDomain: true,
        }
      )
      .then((res) => {
        if (res.data.status == 200) {
          this.setState({
            isLoaded2: false,
            isApply: res.data.isApply,
          });

          if (this.state.isApply) {
            window.location.href = `/apply_success/${this.state.event_public_id}/${this.state.event_category_id}`;
          } else {
            this.setState({
              isLoaded2: true,
              isApply: res.data.isApply,
            });
          }
        } else {
          this.setState({
            isLoaded2: true,
            isApply: false,
          });
        }
      });
  }

  handleSubmit = (event) => {
    // Prevents React from resetting the SyntheticEvent's properties
    //check image is uploaded
    // if (!this.state.imageUrl) {
    //   alert(
    //     "請先選擇照片及等待上載完成再提交。Please select a photo and wait for the upload to complete before submitting."
    //   );
    //   return;
    // }
    event.currentTarget.disabled = true;
    event.preventDefault();
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const { team_name, email, participants } = this.state;
    var param = {};
    var name = "";

    const postData = async () => {
      try {
        // 0. if email is registered, create a unique temp email
        // const postLink="https://dev.diggin.ltd/Dev_DigginHK/apis_v3/web/post/"
        const postLink =
          "https://www.digginhk.com/Diggin_hong_kong_backend/DigginHK/apis_v2/web/post/";

        // const response0 = await axios.get(
        //   "https://www.digginhk.com/Diggin_hong_kong_backend/DigginHK/apis_v2/get/?name=isEmailExist&email=" +
        //     this.state.email,
        //   {
        //     crossDomain: true,
        //   }
        // );
        // console.log(response0);
        // if (response0.data.isExist) {
        //   //email is already registered, create a unique temp email
        //   param["email"] = param["username"] + "@diggin.ltd";
        // }

        // console.log(param);
        //1. create user account
        // const response = await axios.post(
        //   postLink,
        //   { crossDomain: true, name, param },
        //   {
        //     headers: {
        //       // 'application/json' is the modern content-type for JSON, but some
        //       // older servers may use 'text/json'.
        //       // See: http://bit.ly/text-json
        //       "content-type": "application/json;charset=UTF-8",
        //     },
        //   }
        // );
        // console.log(response.data);

        //2. submit event application
        param["eventCategoryId"] = this.state.event_category_id;
        name = "postEventApplication";

        const token  = localStorage.getItem("token");
        const headers = {
          'Authorization': `Bearer ${token}`
        };
        const response2 = await axios.post(postLink, { name, param }, { headers });
        // console.log(response2.data);

        //3. submit event application participant
        for (let i = 0; i < participants.length; i++) {
          
          param["applicationId"] = response2.data.application_id;
          param["teamName"] = team_name;

          param["firstName"] = participants[i].firstName;
          param["lastName"] = participants[i].lastName;
          param["nickname"] = participants[i].nickname;
          param["gender"] = participants[i].gender;
          param["contact"] = participants[i].contact;
          param["q1Ans"] = email;
          name = "postEventParticipant";
          const response3 = await axios.post(postLink, { name, param }, { headers });
          // console.log(response3.data);
        }

        //4. update free payment record if price is 0
        if (this.state.category_price == "0") {
          param["eventId"] = this.state.eventObj.event_id;
          param["eventCategoryId"] = this.state.event_category_id;
          param["applicationId"] = response2.data.application_id;
          param["price"] = "0";
          name = "updateFreePaymentRecord";
          
          const response4 = await axios.post(postLink, { name, param }, { headers });
          if (response4.data.message == "Payment record is updated") {
            window.location.href = `/apply_success/${this.state.event_public_id}/${this.state.event_category_id}`;
          } else {
            alert('please try again');
            event.currentTarget.disabled = false;
          }
          // console.log(response4.data);
        }

        //Other. update image path to db event application: get image url from ImageUpload.js
        // param["url"] = this.state.imageUrl;
        // name = "updateWebPaySlipUrl";
        // const response4 = await axios.post(postLink, { name, param });
        // this.setState({ open: true });
      } catch (error) {
        // Handle errors
        event.currentTarget.disabled = false;
        console.log(error);
      }
    };
    postData();
  };

  handleTeamNameChange = (event) => {
    this.setState({ team_name: event.target.value });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleFirstNameChange = (event, index) => {
    const participants = [...this.state.participants];
    participants[index].firstName = event.target.value;
    this.setState({ participants });
    // this.setState({ firstName: event.target.value });
  };
  handleLastNameChange = (event, index) => {
    const participants = [...this.state.participants];
    participants[index].lastName = event.target.value;
    this.setState({ participants });
    // this.setState({ lastName: event.target.value });
  };
  handleNicknameChange = (event, index) => {
    const participants = [...this.state.participants];
    participants[index].nickname = event.target.value;
    this.setState({ participants });
    // this.setState({ nickname: event.target.value });
  };
  handleGenderChange = (event, index) => {
    const participants = [...this.state.participants];
    participants[index].gender = event.target.value;
    this.setState({ participants });
    // this.setState({ gender: event.target.value });
  };
  handleContactChange = (event, index) => {
    const participants = [...this.state.participants];
    participants[index].contact = event.target.value;
    this.setState({ participants });
    // this.setState({ contact: event.target.value });
  };

  handleCallback = (childData) => {
    // alert("handleCallback");
    // Update the name in the component's state
    this.setState({ imageUrl: childData });
    // console.log(childData);
  };

  render() {
    const { t } = this.props;
    const token = localStorage.getItem("token");
    var { isLoaded, isLoaded2, isApply, eventObj, category_name, category_currency, category_price, total_participants } = this.state;

    if (!isLoaded || !isLoaded2) {
      return <div></div>;
    } else {
      if (!token) {
        return (
          <EventPageContainer>
            <div>
              <TitleText>{t("login_to_access_page")}</TitleText>
            </div>
          </EventPageContainer>
        );
      }

      if (this.state.valid) {
        let deadline = new Date(this.state.eventObj.applicationDeadline);
        let deadline_ts = Date.parse(deadline);
        let currentts = Date.now();

        if (currentts > deadline_ts) {
          return (
            <EventPageContainer>
              <TitleText>{t("event_application_end")}</TitleText>
            </EventPageContainer>
          );
        } else {
          return (
            <EventPageContainer>
              <ContentSection>
                <img
                  style={img}
                  srcSet={eventObj.eventImageUrl}
                  alt="Event Image"
                />
                <br />
                <br />
                <TitleText>{eventObj.eventName}</TitleText>

                <br />
                <br />
                <SubTitleText>{category_name}</SubTitleText>
                <SubTitleText>
                  {category_price !== "0" ? `${category_currency} ${category_price}` : t("free")}
                </SubTitleText>
                <ContentText>
                <form onSubmit={this.handleSubmit}>
                <p></p>

                {total_participants > 1 && (
                    <TextField
                    id="outlined-basic"
                    label={t("teamname")}
                    variant="outlined"
                    fullWidth
                    name="tema_name"
                    onChange={this.handleTeamNameChange}
                    required
                    />
                )}

                <br/>
                <p></p>
                <TextField
                      id="outlined-basic"
                      label={t("email")}
                      variant="outlined"
                      fullWidth
                      type={"email"}
                      name="email"
                      onChange={this.handleEmailChange}
                      required
                    />
                    <p></p>

                  {[...Array(total_participants)].map((_, index) => (
                    <div key={index}>
                      <br/>
                      <p></p>
                      {t("participant")} {index + 1}:
                      <p></p>
                      <TextField
                        id={`firstName-${index}`}
                        label={t("firstname")}
                        variant="outlined"
                        fullWidth
                        name={`firstName-${index}`}
                        onChange={(e) => this.handleFirstNameChange(e, index)}
                        required
                      />
                      <p></p>
                      <TextField
                        id={`lastName-${index}`}
                        label={t("lastname")}
                        variant="outlined"
                        fullWidth
                        name={`lastName-${index}`}
                        onChange={(e) => this.handleLastNameChange(e, index)}
                        required
                      />
                      <p></p>

                      <TextField
                        id={`nickname-${index}`}
                        label={t("nickname")}
                        variant="outlined"
                        fullWidth
                        name={`nickname-${index}`}
                        onChange={(e) => this.handleNicknameChange(e, index)}
                        required
                      />
                      <p></p>

                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id={`gender-label-${index}`}>{t("gender")}</InputLabel>
                        <Select
                          labelId={`gender-label-${index}`}
                          id={`gender-${index}`}
                          label="gender"
                          onChange={(e) => this.handleGenderChange(e, index)}
                          required
                        >
                          <MenuItem value="M">{t("male")}</MenuItem>
                          <MenuItem value="F">{t("female")}</MenuItem>
                        </Select>
                      </FormControl>
                      <p></p>
                      <TextField
                        id={`contact-${index}`}
                        label={t("contact")}
                        variant="outlined"
                        fullWidth
                        type="number"
                        name={`contact-${index}`}
                        onChange={(e) => this.handleContactChange(e, index)}
                        required
                      />

                      <p></p>
                      
                    </div>
                  ))}
                  <br />
                  <Button type="submit">{t("confirm")}</Button>
                </form>
                </ContentText>
              </ContentSection>
            </EventPageContainer>
          );
        }
      } else {
        return (
          <div style={{ marginTop: 180, marginBottom: 160 }}>
            <Typography style={style}>{t("event_not_available")}</Typography>
          </div>
        );
      }
    }
  }
}
export default withTranslation()(EventApplicationFormMobile);
