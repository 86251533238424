import styled from "styled-components";

export const NavBarContainer = styled.div`
  height: 80px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const LeftSection = styled.div`
  display: flex;
`;

export const MiddleSection = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
  justify-content: center;
`;

export const RightSection = styled.div`
  display: flex;
`;
