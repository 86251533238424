import React, {useState,useEffect} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive'
import { Container } from '@mui/material';
import { TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { Navbar } from "../Navbar";

const useStyles = makeStyles({
  
});

export const PageContainer = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1.5em;
  background-color: #fff;
  white-space: pre-line;
  font-family: "Noto Sans";

  a:link {
    text-decoration: none;
    color: #191919;
  }

  a:visited {
    text-decoration: none;
    color: #191919;
  }

  a:hover {
    text-decoration: none;
    color: #53B0AE;
  }

  a:active {
    text-decoration: none;
  }
`;


const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const TextFieldLabel = styled.div`
  height: 100%;
//   padding: 0 1.1em;
  color: #191919;
  font-weight: normal;
  font-size: 18px;
  align-items: left;
  justify-content: left;
  display: flex;
  fontFamily: "Noto Sans",
`;



const Button = styled.button`
    background-color: #53B0AE;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    outline: 0;
    text-transform: uppercase;
    margin: 10px 0px;
    cursor: pointer;
    border: 0;
    height: 44px;
    width: 100%;
    box-shadow: 0px 2px 2px lightgray;
    transition: ease background-color 250ms;
    &:hover {
      background-color: #53B0AE;
    }
    &:disabled {
      cursor: default;
      opacity: 0.7;
    }
  `;
  
  Button.defaultProps = {
    theme: "green"
  };

export function RegisterMainDesktop(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

    const handleUsernameChange = event => {
      setUsername(event.target.value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = event => {
      setPassword(event.target.value);
  };

  const handleSubmit = async event => {
      event.preventDefault();
      event.currentTarget.disabled = true;

      try {
        setIsLoading(true); // Set loading state to true
          const currentDate = new Date();
          const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          }).format(currentDate);

          const postLink = "https://www.digginhk.com/Diggin_hong_kong_backend/DigginHK/apis_v2/";
          const response = await axios.post(postLink, {
              crossDomain: true,
              name: "register",
              param: {
                  username: username,
                  password: password,
                  email: email,
                  appleUserID: "",
                  createTime: formattedDate,
              }
          }, {
              headers: {
                  'content-type': 'application/json;charset=UTF-8'
              }
          });
          
          if (response.data.isSend) {
            alert("register success");
            window.location.href = '/login';
          } else {
              alert("register failure: "+response.data.message);
          }
      } catch (error) {
          event.currentTarget.disabled = false;
          // console.log(error);
      } finally {
        event.currentTarget.disabled = false;
        setIsLoading(false); // Set loading state to false
      }
  };

  const token = localStorage.getItem('token');
  if (token) {
    return (
        <div style={{ marginTop: 180, marginBottom: 160 }}>
            <Navbar />
            <TitleText>{t('not_available')}</TitleText>
        </div>
    );
} else {
  return (
    <div style={{ marginTop: 160, marginBottom: 160 }}>
            <Navbar />
            <Typography>
                <PageContainer>
                    <TitleText>{t('register')}</TitleText>
                    <div style={{ marginBottom: 60 }} />
                    <form onSubmit={handleSubmit}>
                    
                    
                    <TextFieldLabel>{t('username_for_login')}</TextFieldLabel>
                    
                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            className={classes.textField}
                            onChange={handleUsernameChange}
                        />
                        
                        <div style={{ marginBottom: 20 }} />
                        <TextFieldLabel>{t('email')}</TextFieldLabel>
                    
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        className={classes.textField}
                        onChange={handleEmailChange}
                    />
                    
                    <div style={{ marginBottom: 20 }} />

                        <TextFieldLabel>{t('password')}</TextFieldLabel>
                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            autoFocus
                            className={classes.textField}
                            onChange={handlePasswordChange}
                        />
                        <div style={{ marginBottom: 60 }} />
                        
                        {/* <Button type="submit"> {t('register')} </Button> */}
                        <Button type="submit" disabled={isLoading}>
                        {isLoading ? 'Loading...' : t('register')}
                      </Button>
                        <Container style={{ padding: "0px 0px 0px 10px" }}>
                        </Container>
                    </form>
                    <div style={{ marginBottom: 10 }} />
                    <a style={{ textDecoration: 'none', marginLeft: 'auto' }} href={'/login'}>{t('login')}</a>
                </PageContainer>
            </Typography>
        </div>
  );
}
}
