import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Container } from '@mui/material';
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    minWidth: 240,
    height: 500
  },
});

const EventCard = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pid, imageUrl, eventName, startTime, endTime, eventTypeChinese, eventTypeEng, eventAddress } = props;

  return(
    <Card className={classes.root}>
      <CardActionArea href={'/event/'+pid }>
        <CardMedia
          component="img"
          alt={eventName}
          height="270"
          width="200"
          image={imageUrl}
          title={eventName}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t(String(eventTypeEng))}
          </Typography>
          <Typography gutterBottom variant="body1" component="h2">
            {eventName.length > 43 ? `${eventName.substring(0, 43)}...` : eventName}
          </Typography>

          <Container style={{padding: "15px 0px"}}>
            <Typography variant="caption" component="p">
            <div style={{display:"flex"}}>
              <RoomOutlinedIcon style={{fontSize:"medium"}}/>
              <Container style={{padding: "0px 0px 0px 10px"}}>
              {eventAddress.length > 18 ? `${eventAddress.substring(0, 18)}...` : eventAddress}
              </Container>
            </div>
            </Typography>
            <p></p>
            <Typography variant="caption" component="p">
              <div style={{display:"flex"}}>
                <AccessTimeIcon style={{fontSize:"medium"}}/> <Container style={{padding: "0px 0px 0px 10px"}}> {startTime.slice(0, -3).replaceAll("-", ".")} - {endTime.slice(0, -3).replaceAll("-", ".")} </Container>
              </div>
            </Typography>
          </Container>

        </CardContent>
      </CardActionArea>
      {/*
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
      */}
    </Card>
  );
}

export default EventCard;
