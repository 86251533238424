import React from 'react'
import { makeStyles } from "@mui/styles";
import { Typography, Link } from "@mui/material";
import facebook_logo from '../images/facebook_logo.png';
import instagram_logo from '../images/instagram_logo.png';
import whatsapp_logo from '../images/whatsapp_logo.png';
import email_logo from '../images/email_logo.png';
import linkedin_logo from '../images/linkedin_logo.png';
import iOS_download from '../images/iOS_download.png';
import android_download from '../images/android_download.png';
import qrcode from '../images/qrcode.png'
import { HomepageDesktopFooter} from "./HomepageDesktopFooter";
import { HomepageMobileFooter} from "./HomepageMobileFooter";
import { useMediaQuery } from 'react-responsive';

function HomepageFooter() {
      const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
      })
      return (
        <div>
        {!isMobile && <HomepageDesktopFooter />}
        {isMobile && <HomepageMobileFooter />}
        </div>
    )
}

export default HomepageFooter;
