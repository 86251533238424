import React from "react";
import styled from "styled-components";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";
import logo from '../images/logo512.png';
import diggin_team from '../images/diggin_event_thefirst3.jpg';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMediaQuery } from 'react-responsive'
import { Container } from '@mui/material';
import iOS_download from '../images/iOS_download.png';
import android_download from '../images/android_download.png';
import qrcode from '../images/qrcode.png'

export const AboutUsContainer = styled.div`
  height: 100%;
  display: flex;

  align-items: center;
  padding: 0 1.5em;
  background-color: #fff;
  margin-left: 100px;
  margin-right: 100px;
`;

export const LeftSection = styled.div`
  display: flex;
  flex: 1;
  margin-right: 32px;
  justify-content: center;

  flex-direction: column;

  a:link {
    text-decoration: none;
    color: #191919;
  }

  a:visited {
    text-decoration: none;
    color: #191919;
  }

  a:hover {
    text-decoration: none;
    color: #000;
  }

  a:active {
    text-decoration: none;
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

export function AboutUsMainDesktop(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  return (
    <AboutUsContainer>
      <LeftSection>

      <img src={diggin_team} style={{width:"438px" , height:"210px"}}/>
      <div style={{marginTop: 20}}/>
      <Typography variant="caption" component="p">
        <div style={{display:"flex"}}>
          <EmailIcon style={{fontSize:"large",marginTop:1}}/>
          <Container style={{padding: "0px 0px 10px 10px"}}>
          <a href="mailto:contact@diggin.ltd">
          contact@diggin.ltd
          </a>
          </Container>
        </div>
      </Typography>
      <Typography variant="caption" component="p">
        <div style={{display:"flex"}}>
          <WhatsAppIcon style={{fontSize:"large",marginTop:1}}/>
          <Container style={{padding: "0px 0px 10px 10px"}}>
          <a target="_blank" href="https://wa.me/85298503893">
          +852 98503893
          </a>
          </Container>
        </div>
      </Typography>
      <Typography variant="caption" component="p">
        <div style={{display:"flex"}}>
          <InstagramIcon style={{fontSize:"large",marginTop:1}}/>
          <Container style={{padding: "0px 0px 10px 10px"}}>
          <a target="_blank"  href="https://www.instagram.com/digginmobileofficial/">
          digginmobileofficial
          </a>
          </Container>
        </div>
      </Typography>
      <Typography variant="caption" component="p">
        <div style={{display:"flex"}}>
          <FacebookIcon style={{fontSize:"large",marginTop:1}}/>
          <Container style={{padding: "0px 0px 10px 10px"}}>
          <a target="_blank" href="https://www.facebook.com/DigginOfficial">
          DigginOfficial
          </a>
          </Container>
        </div>
      </Typography>
      <Typography variant="caption" component="p">
        <div style={{display:"flex"}}>
          <LinkedInIcon style={{fontSize:"large",marginTop:1}}/>
          <Container style={{padding: "0px 0px 10px 10px"}}>
          <a target="_blank" href="https://hk.linkedin.com/company/digginltd">
          digginltd
          </a></Container>
        </div>
      </Typography>
      <Typography variant="caption" component="p">
        <div style={{display:"flex"}}>
          <LocationOnIcon style={{fontSize:"large",marginTop:1}}/>
          <Container style={{padding: "0px 0px 10px 10px"}}>
          <a target="_blank" href="https://maps.app.goo.gl/tew46SqYLugUoDkd8">
          {t('company_address')}
          </a>
          </Container>
        </div>
      </Typography>
      </LeftSection>
      <RightSection>
      <TitleText>{t('about_us')}</TitleText>
      <br/><br/>
      {t('about_diggin2')}
      <br/><br/>
      {t('about_diggin')}

      <br/><br/>
      {t('download_app')}
      <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", padding: "10px 0px"}}>
        <img src={qrcode} style={{ padding:"0px 24px 0px 0px", height:"72px", width:"72px"}} />
          <div style={{display: "grid"}}>
            <a target="_blank" href="https://apps.apple.com/app/diggin-dig-it-dance-it/id1123086521"><img src={iOS_download} height="32" width="108"/> </a> <a target="_blank" href="https://play.google.com/store/apps/details?id=com.lcproducts.digginghk" style={{padding: "5px 0px"}}><img src={android_download} height="32" width="108"/></a>
          </div>
      </div>
      </RightSection>


    </AboutUsContainer>
  );
}
