import React from "react";
import styled from "styled-components";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Navbar } from "../Navbar";
import { Typography, Link } from "@mui/material";
import HomepageFooter from "../Footers/HomepageFooter";
import video from '../images/diggin_video_en.mp4'
import poster from "../images/poster_en.png";
import EventHomepage from "../Event/EventHomePage";
import StudioPage from "../StudioPage/StudioPage";
import EcommercePage from "../EcommercePage/EcommercePage";
import { Player, BigPlayButton } from "video-react";
import "../../../../node_modules/video-react/dist/video-react.css";
import "../../../App.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

var style = {
    fontSize: 28,
    position: "relative",
    fontFamily: "Noto Sans",
    height: "30px",
  },
  buttonstyle = {
    textAlign: "right",
    position: "relative",
    fontFamily: "Noto Sans",
    padding: "0px 0px 24px",
  };

const Wrapper = styled.div`
  display: relative;
  width: 100%;
`;

const Marginer = styled.div`
  height: 80px;
`;

export function HomeDetails(props) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const downloadApp = () => {
    window.open("https://bit.ly/DigginApp", "_blank");
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Wrapper>
        <Navbar />
        {/* Video*/}
        <Marginer />
        <Player
            playsInline
            poster={poster}
            src={video}
            type={video}
            width="200px" >
            <BigPlayButton position="center" />
        </Player>

        <div style={{ padding: "60px 50px" }}>
          {/* Event */}
          <Typography style={style}>{t("coming_events")}</Typography>

          <div style={buttonstyle}>
            <Link href="/events/0/0/0" style={{ fontSize: 16 }}>
              {t("more")}
            </Link>
          </div>

          <EventHomepage />

          {/* Studio */}
          <br />
          <br />
          <Typography style={style}>{t("studios")}</Typography>

          <div style={buttonstyle}>
            <Link onClick={handleClickOpen} style={{ fontSize: 16 }}>
              {t("more")}
            </Link>
          </div>

          <StudioPage />

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("explore_more")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("download_app_eplore")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("cancel")}</Button>
              <Button onClick={downloadApp} autoFocus>
                {t("download")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* E-Commerce */}
          {/*
            <Container style={{padding: "60px 0px 0px 0px"}}>
                <Typography style={style}>
                    Featured Products
                </Typography>
                <div style={buttonstyle}>
                    <Link href="https://shop.diggin.ltd/" style={{fontSize: 16}}>
                        View all
                    </Link>
                </div>
                <EcommercePage />
            </Container>
        */}
        </div>

        <HomepageFooter />
      </Wrapper>
    </div>
  );
}
