import styled from "styled-components";
import DLogoImg from "../Root/images/logo_white_100.png";
import React from 'react';


const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImg = styled.div`
  width: 80px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const LogoText = styled.h2`
  font-size: 16px;
  margin: 0;
  margin-left: 4px;
  color: #222;
  font-weight: 500;
`

export function Logo(props) {
  return <LogoWrapper>
    <LogoImg>
    <a href="/home"><img href="/home" src={DLogoImg} alt="D logo"/></a></LogoImg>
    <LogoText></LogoText>
  </LogoWrapper>
}
