import React from 'react'
import ios_download from '../images/iOS_download.png';
import android_download from '../images/android_download.png';
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

var style = {
    backgroundColor: "#53B0AE",
    textAlign: "center",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "80px",
    width: "100%",
}

var phantom = {
  display: 'block',
  padding: '10px',
  height: '80px',
  width: '100%',
}

const useStyles = makeStyles(() => ({
  whiteColor: {
    color: '#ffffff',
    fontSize: 18,
  }
}));

function DownloadFooter() {
    const classes = useStyles();
    return (
        <div>
          <div style={phantom} />
            <div style={style}>
              <Typography className={classes.whiteColor}>
                立即下載 查看更多舞蹈資訊
              </Typography>
              <p></p>
              <a target="_blank" href="https://www.digginhk.com/download/iOS/"><img src={ios_download} width="100" height="35"/></a>  <a target="_blank" href="https://www.digginhk.com/download/Android/"><img src={android_download} width="100" height="35" /></a>
            </div>
        </div>
    )
}

export default DownloadFooter;
