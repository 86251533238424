import React from 'react'
import { makeStyles } from '@mui/styles';
import ProductContent from '../EcommercePage/ProductContent';
import ImageList from '@mui/material/ImageList';
import { Container } from '@mui/material';

const useStyles = makeStyles(() => ({
  container: {
    padding: "10px 0px 0px 0px",
    height: "445px"
  },

  imageList: {
    flexWrap: 'nowrap',
    margin: "0px !important",
    transform: 'translateZ(0)',
    width: "100%",
    height: "100%",
    flexDirection: "row"
  },
}));

function EcommercePage () {
  const classes = useStyles();
    return (
      <Container className={classes.container}>
        <ImageList className={classes.imageList} >
          <ProductContent />
        </ImageList>
      </Container>
    );
};

export default EcommercePage;
