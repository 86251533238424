import React, { Component } from 'react'
import { Grid } from "@mui/material";
import eventsList from '../Event/constants';
import axios from 'axios'
import "../../../App.css";
import styled from "styled-components";
import i18n from '../../../i18n';
import { withTranslation } from "react-i18next";

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53B0AE;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const SubTitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const getEventCategoryTitle = categoryObj => {
  return(
    <Grid item xs={12} sm={6} md={4}>
    <SubTitleText>
    <a href={'/record_details/'+categoryObj.categoryId+'/'+categoryObj.selectionMode}>
    {categoryObj.categoryName}
    </a>
    </SubTitleText>
    </Grid>
  );
};

class EventCategoryList extends Component {

  constructor() {
  super();
  }

  state = {
    resultData: [],
    isLoaded: false,
  }

  componentDidMount() {
      var instance = axios.create({ baseURL: 'https://www.digginhk.com' });
      instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=getEventCateories&pid='+this.props.match.params.event_public_id, {
      crossDomain: true
    })
      .then(res => {
        this.setState({
          resultData: res.data,
          isLoaded: true,
        });
      });
  }

  render() {
    var { isLoaded, resultData } = this.state;
    const { t } = this.props;
    if (!isLoaded) {
      return (
        <div></div>
      );
    } else {

      return (
        <div>
        <TitleText>{t('event_records')}</TitleText>
        <TitleText>{resultData.eventName}</TitleText>
        <div style={{marginTop: 40}}/>
        <Grid container spacing={4}>
          {resultData.categories.map(categoryObj => getEventCategoryTitle(categoryObj))}
        </Grid>

        <div style={{marginTop: 20}}/>
        </div>
      );
    }
  };
};

export default withTranslation()(EventCategoryList);
