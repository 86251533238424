import React, { Component } from "react";

class NotAvailable extends Component {
  constructor(props) {
    super();
    this.state = { ...props };
  }
  componentWillMount() {
    window.location = "https://diggin.ltd";
  }
  render() {
    return <section></section>;
  }
}

export default NotAvailable;
