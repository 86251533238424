import React, { Component } from 'react'
import { Grid } from "@mui/material";
import eventsList from '../Event/constants';
import axios from 'axios'
import "../../../App.css";
import styled from "styled-components";

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontFamily: "Noto Sans",
`;

const getEventTitle = eventObj => {
  const { pid } = eventObj;
  return(
    <Grid item xs={12} sm={12} md={12}>
    <TitleText>
    <a href={'/record/'+pid }>
    {eventObj.eventName}
    </a>
    </TitleText>
    </Grid>
  );
};

class RecordsList extends Component {

  constructor() {
  super();
  }

  state = {
    events: [],
    isLoaded: false,
  }

  componentDidMount() {
      var instance = axios.create({ baseURL: 'https://www.digginhk.com' });
      instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=getEventRecordsList', {
      crossDomain: true
    })
      .then(res => {
        this.setState({
          events: res.data,
          isLoaded: true,
        });
      });
  }

  render() {
    var { isLoaded, events } = this.state;
    if (!isLoaded) {
      return (
        <div></div>
      );
    } else {

      return (
        <Grid container spacing={4}>
          {events.events.map(eventObj => getEventTitle(eventObj))}
        </Grid>

      );
    }
  };
};

export default RecordsList;
