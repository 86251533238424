import React, { Component } from 'react'
import { Container, Grid } from '@mui/material';
import Content from './Content';
import HomepageHeader from '../Header/HomepageHeader';
import HomepageFooter from '../Footers/HomepageFooter';
import Header from '../Header/Header';
import DownloadFooter from '../Footers/DownloadFooter';
import { Navbar } from '../Navbar';
import styled from "styled-components";
import i18n from '../../../i18n';
import { withTranslation } from "react-i18next";


class DistrictCategory extends Component {

  render() {
    const { t } = this.props;
    const countryId = this.props.countryId;
    if (countryId=="235") {
      return (

        <div>
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/0/0">{t('all')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/5/0">{t('north')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/6/0">{t('middle')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/7/0">{t('south')}</a> |
        <a style={{color: "#191919", textDecoration: 'none',marginLeft: 3}} href="/events/235/8/0">{t('east')}</a>
        </div>
      );
    } else {
      return(
        <div>
        </div>
      );
    }

  }
}

export default withTranslation()(DistrictCategory);
