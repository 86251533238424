import React, { Component } from 'react'
import StudioCard from './StudioCard';
import { Container, ImageList } from "@mui/material";
// import ImageList from '@mui/material/ImageList';
import axios from 'axios'

var container = {
  display: 'inline-block',
  flex: 'nowrap',
  marginLeft: '0',
  paddingLeft: '0',
  width: '500px'
},

root = {
  display: 'inline-block',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  overflow: 'hidden',
  height: "100%"
},

imageList = {
  flexWrap: 'nowrap',
  height: '250px',
  width: '2500px',
  overflow: 'hidden'
},

gridList = {
  flexWrap: 'nowrap',
  height: '250px',
  width: '400px',
  overflow: 'hidden',
  width: "100%"
}

const getStudioCard = studioObj => {
  return (
      <ImageList style={gridList} >
        <StudioCard {...studioObj} />
      </ImageList>
  );
};

class StudioContent extends Component {

    state = {
      studios: [],
      isLoaded: false,
    }

    constructor() {
      super();
      var instance = axios.create({ baseURL: 'https://www.digginhk.com' });
      instance.get('/Diggin_hong_kong_backend/DigginHK/apis_v2/web/get/?name=getHomeFeaturedStudios')
      .then(res => {
        this.setState({
          result: res.data,
          isLoaded: true,
        });
      });
    }

    render() {
      var { isLoaded, result } = this.state;
      if (!isLoaded) {
        return (
          <div></div>
        );
      } else {
        return (
          <Container style={container}>
            <div style={root}>


              {/* <ImageList style={imageList} > */}
              <ImageList variant="fullWidth" cols={5} gap={8} style={imageList} >
                {result.studios.map(studioObj => getStudioCard(studioObj))}
              </ImageList>

            </div>
          </Container>
        );
      }
    }
};


export default StudioContent;
