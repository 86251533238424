import React from 'react'
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    height: "245px !important",
    width: "500px",
    overflow: "hidden",
    borderRadius: "10px",
    border: "black",

  },

  imageList:{
    flexWrap: 'nowrap',
    height: '240px',
    overflow: 'hidden',
    margin: "0px 0px 0px 2px !important",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },

  gridList: {
    height: "240px !important",
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    margin: "0px !important",
    padding: "0px !important",
  },

  card: {
    margin: "0px 20px 0px 0px",
    height: "230px !important",
    padding: "0px !important",
    minWidth: "450px",
    borderRadius: "10px",

  },

  image: {
    '&:hover': {
      transform: "scale(1.2)"
    }
  },

  text: {
    position: 'absolute',
    top: '24px',
    left: '24px',
    color: "white",
    fontWeight: "bold"
  }
}
));

const StudioCard = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { studioName, imageUrl, studioAddress, latitude, longitude, district_abbreviation } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const downloadApp = () => {
    window.open("https://bit.ly/DigginApp", "_blank");

  };

  const handleClose = () => {
    setOpen(false);
  };
    return (
      <div className={classes.root}>
            <Card className={classes.card}>
                <CardActionArea onClick={handleClickOpen}>
                  <div style={{filter: "brightness(70%)"}}>
                    <CardMedia
                      className={classes.image}
                      component="img"
                      alt={studioName}
                      height="230"
                      width="400"
                      image={imageUrl}
                      title={studioName}
                    />
                  </div>

                  <div className={classes.text}>
                    <Typography gutterBottom variant="caption" component="h2" style={{margin: "0px"}}>
                      {t(String(district_abbreviation))}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h2" style={{fontWeight: "bold"}}>
                      {studioName}
                    </Typography>
                  </div>
                </CardActionArea>
            </Card>


        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
              {t('explore_more')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t('download_app_eplore')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <Button onClick={downloadApp} autoFocus>
              {t('download')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}
export default StudioCard;
