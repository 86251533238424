import React, { useEffect } from "react";
import styled from "styled-components";
import i18n from "../../../i18n";
import { Navbar } from "../Navbar";
import HomepageFooter from "../Footers/HomepageFooter";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Container, Button } from '@mui/material';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { makeStyles } from "@mui/styles";
import { Player, BigPlayButton } from "video-react";
// import video from '../images/PopcityWorldFinal2019Seoul.mp4'
import poster from "../images/popcity_poster.png";
import { ClientsOthers } from "./clients_others";
import ResponsiveEmbed from "react-responsive-embed";

import hkdsa_breaking_team1 from "../images/clients/hkdsa/2021_hkdsa_breaking_team1.jpeg";
import hkdsa_breaking_team2 from "../images/clients/hkdsa/2021_hkdsa_breaking_team2.jpeg";
import hkdsa_breaking_team3 from "../images/clients/hkdsa/2021_hkdsa_breaking_team3.jpeg";
import hkdsa_breaking_team4 from "../images/clients/hkdsa/2021_hkdsa_breaking_team4.jpeg";
import hkdsa_breaking_team5 from "../images/clients/hkdsa/2021_hkdsa_breaking_team5.jpeg";
import hkdsa_breaking_team6 from "../images/clients/hkdsa/2021_hkdsa_breaking_team6.jpeg";

import hhi_1 from "../images/clients/HHI2020/hhi_2020_1.png";
import hhi_2 from "../images/clients/HHI2020/hhi_2020_2.png";
import hhi_3 from "../images/clients/HHI2020/hhi_2020_3.png";
import hhi_4 from "../images/clients/HHI2020/hhi_2020_4.png";
import hhi_5 from "../images/clients/HHI2020/hhi_2020_5.png";

import break_to_prove1 from "../images/clients/BreakToProve/break_to_prove1.png";
import break_to_prove2 from "../images/clients/BreakToProve/break_to_prove2.png";
import break_to_prove3 from "../images/clients/BreakToProve/break_to_prove3.png";

export const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0 1.5em;
  background-color: #fff;
  white-space: pre-line;
  fontfamily: "Noto Sans";
`;

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53b0ae;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const SubTitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const ContentText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const FormButton = styled.button`
  border: 0;
  outline: 0;
  padding: 8px 1em;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 18px;
  background-color: #53b0ae;
  cursor: pointer;
`;

const useStyles = makeStyles({
  a: {
    display: "block",
    height: "100%",
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
});

var cElement = null;

export function Clients(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 992px)",
  });

  return (
    <div>
      <Navbar />
      <div style={{ marginTop: 120 }} />
      <ClientsContainer>
        <TitleText>{t("clients_and_partners")}</TitleText>
        <br />
        <ContentText>
          <a style={{ marginRight: 12 }} href="/clients">
            <FormButton>{t("devents_cases")}</FormButton>
          </a>
          <a href="/clients_others">
            <FormButton>{t("others")}</FormButton>
          </a>
        </ContentText>
        <div style={{ marginTop: 20 }} />
        <ContentText>
          <a
            style={{
              color: "#191919",
              textDecoration: "none",
              marginRight: 3,
              marginLeft: 3,
            }}
            href="#hong_kong"
          >
            {t("hong_kong")}
          </a>{" "}
          |
          <a
            style={{
              color: "#191919",
              textDecoration: "none",
              marginRight: 3,
              marginLeft: 3,
            }}
            href="#taiwan"
          >
            {t("taiwan")}
          </a>{" "}
          |
          <a
            style={{
              color: "#191919",
              textDecoration: "none",
              marginRight: 3,
              marginLeft: 3,
            }}
            href="#korea"
          >
            {t("south_korea")}
          </a>{" "}
          |
          <a
            style={{
              color: "#191919",
              textDecoration: "none",
              marginRight: 3,
              marginLeft: 3,
            }}
            href="#malaysia"
          >
            {t("malaysia")}
          </a>{" "}
          |
          <a
            style={{ color: "#191919", textDecoration: "none", marginLeft: 3 }}
            href="#australia"
          >
            {t("australia")}
          </a>
        </ContentText>
        <a id="hong_kong">
          <br />
          <br />
          <br />
          <br />
          <SubTitleText>{t("hong_kong")}</SubTitleText>
          <br />
          <SubTitleText>2021 香港霹靂舞隊精英選拔賽</SubTitleText>
          <br />
          <ImageList cols={3} rowHeight={260}>
            <ImageListItem key="2021 hkdsa breaking competition">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={hkdsa_breaking_team1}
                  alt="2021 hkdsa breaking competition"
                />
              </a>
            </ImageListItem>
            <ImageListItem key="2021 hkdsa breaking competition">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={hkdsa_breaking_team2}
                  alt="2021 hkdsa breaking competition"
                />
              </a>
            </ImageListItem>
            <ImageListItem key="2021 hkdsa breaking competition">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={hkdsa_breaking_team3}
                  alt="2021 hkdsa breaking competition"
                />
              </a>
            </ImageListItem>
            <ImageListItem key="2021 hkdsa breaking competition">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={hkdsa_breaking_team4}
                  alt="2021 hkdsa breaking competition"
                />
              </a>
            </ImageListItem>
            <ImageListItem key="2021 hkdsa breaking competition">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={hkdsa_breaking_team5}
                  alt="2021 hkdsa breaking competition"
                />
              </a>
            </ImageListItem>
            <ImageListItem key="2021 hkdsa breaking competition">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={hkdsa_breaking_team6}
                  alt="2021 hkdsa breaking competition"
                />
              </a>
            </ImageListItem>
          </ImageList>
        </a>

        <a id="taiwan">
          <br />
          <br />
          <br />
          <br />
          <SubTitleText>{t("taiwan")}</SubTitleText>
          <br />
          <SubTitleText>
            2020 DANCE FOR HIGH ASIAN FINAL  亞洲街舞公開賽亞洲總決賽
          </SubTitleText>
          <br />
          <ImageList cols={3} rowHeight={260}>
            <ImageListItem key="2020 HHI">
              <a className={classes.a} href="#">
                <img className={classes.img} srcSet={hhi_1} alt="2020 HHI" />
              </a>
            </ImageListItem>
            <ImageListItem key="2020 HHI">
              <a className={classes.a} href="#">
                <img className={classes.img} srcSet={hhi_2} alt="2020 HHI" />
              </a>
            </ImageListItem>
            <ImageListItem key="2020 HHI">
              <a className={classes.a} href="#">
                <img className={classes.img} srcSet={hhi_3} alt="2020 HHI" />
              </a>
            </ImageListItem>
            <ImageListItem key="2020 HHI">
              <a className={classes.a} href="#">
                <img className={classes.img} srcSet={hhi_4} alt="2020 HHI" />
              </a>
            </ImageListItem>
            <ImageListItem key="2020 HHI">
              <a className={classes.a} href="#">
                <img className={classes.img} srcSet={hhi_5} alt="2020 HHI" />
              </a>
            </ImageListItem>
          </ImageList>
        </a>

        <a id="korea">
          <br />
          <br />
          <br />
          <br />
          <SubTitleText>{t("south_korea")}</SubTitleText>
          <br />
          <SubTitleText>Pop City Final Korea 2019</SubTitleText>
          <br />
          {/* <Player
                playsInline
                poster={poster}
                src={video}
                type={video}
                width="200px" >
                <BigPlayButton position="center" />
            </Player> */}
        </a>

        <a id="malaysia">
          <br />
          <br />
          <br />
          <br />
          <SubTitleText>{t("malaysia")}</SubTitleText>
          <br />
          <SubTitleText>Break to Prove</SubTitleText>
          <br />
          <ImageList cols={3} rowHeight={260}>
            <ImageListItem key="BreakToProve">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={break_to_prove1}
                  alt="Break To Prove"
                />
              </a>
            </ImageListItem>
            <ImageListItem key="BreakToProve">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={break_to_prove2}
                  alt="Break To Prove"
                />
              </a>
            </ImageListItem>
            <ImageListItem key="BreakToProve">
              <a className={classes.a} href="#">
                <img
                  className={classes.img}
                  srcSet={break_to_prove3}
                  alt="Break To Prove"
                />
              </a>
            </ImageListItem>
          </ImageList>
        </a>

        <a id="australia">
          <br />
          <br />
          <br />
          <br />
          <SubTitleText>{t("australia")}</SubTitleText>
          <br />
          <SubTitleText>2021 Drop the beat vol. 5</SubTitleText>
          <br />
          <ResponsiveEmbed
            src="https://www.youtube.com/embed/Hj95pR7qT0w"
            ratio="4:3"
          />

          <div></div>
        </a>
      </ClientsContainer>
      <div style={{ marginBottom: 100 }} />
      <HomepageFooter />
    </div>
  );
}
