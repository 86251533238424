import React from "react";
import styled from "styled-components";
import i18n from "../../../i18n";
import { Navbar } from "../Navbar";
import HomepageFooter from "../Footers/HomepageFooter";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Container, Button } from '@mui/material';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { makeStyles } from "@mui/styles";
import { Player, BigPlayButton } from "video-react";
// import video from '../images/PopcityWorldFinal2019Seoul.mp4'
import poster from "../images/popcity_poster.png";

import logo1 from "../images/clients/logo/logo1.png";
import logo2 from "../images/clients/logo/logo2.png";
import logo3 from "../images/clients/logo/logo3.png";
import logo4 from "../images/clients/logo/logo4.png";
import logo5 from "../images/clients/logo/logo5.png";
import logo6 from "../images/clients/logo/logo6.png";
import logo7 from "../images/clients/logo/logo7.png";
import logo8 from "../images/clients/logo/logo8.png";
import logo9 from "../images/clients/logo/logo9.png";
import logo10 from "../images/clients/logo/logo10.png";
import hkdsa from "../images/clients/logo/hkdsa.jpeg";
import hksdda from "../images/clients/logo/hksdda.png";
import cyberport from "../images/clients/logo/cyberport.png";
import link from "../images/clients/logo/link.jpeg";

export const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0 1.5em;
  background-color: #fff;
  white-space: pre-line;
  fontfamily: "Noto Sans";
`;

const TitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #53b0ae;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const SubTitleText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const ContentText = styled.div`
  height: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  fontfamily: "Noto Sans";
`;

const FormButton = styled.button`
  border: 0;
  outline: 0;
  padding: 8px 1em;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 18px;
  background-color: #53b0ae;
  cursor: pointer;
`;

const useStyles = makeStyles({
  a: {
    display: "block",
    height: "100%",
  },
  img: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
});

export function ClientsOthers(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 992px)",
  });
  return (
    <div>
      <Navbar />
      <div style={{ marginTop: 120 }} />
      <ClientsContainer>
        <TitleText>{t("clients_and_partners")}</TitleText>
        <br />
        <ContentText>
          <a style={{ marginRight: 12 }} href="/clients">
            <FormButton>{t("devents_cases")}</FormButton>
          </a>
          <a href="/clients_others">
            <FormButton>{t("others")}</FormButton>
          </a>
        </ContentText>
        <div style={{ marginTop: 20 }} />

        <ImageList cols={3} rowHeight={150}>
          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={cyberport} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo1} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo2} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={hkdsa} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo4} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={link} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo5} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo6} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo7} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo8} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={logo9} alt="logo" />
            </a>
          </ImageListItem>

          <ImageListItem key="logo">
            <a className={classes.a} href="#">
              <img className={classes.img} srcSet={hksdda} alt="logo" />
            </a>
          </ImageListItem>
        </ImageList>
      </ClientsContainer>
      <div style={{ marginBottom: 100 }} />
      <HomepageFooter />
    </div>
  );
}
