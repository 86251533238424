import React, { Component } from 'react'
import { Container, Grid } from '@mui/material';
import Content from './Content';
import HomepageHeader from '../Header/HomepageHeader';
import HomepageFooter from '../Footers/HomepageFooter';
import Header from '../Header/Header';
import DownloadFooter from '../Footers/DownloadFooter';
import { Navbar } from '../Navbar';
import styled from "styled-components";
import i18n from '../../../i18n';
import { withTranslation } from "react-i18next";


class District extends Component {

  render() {
    const { t } = this.props;
    const districtCatId = this.props.districtCatId;
    if (districtCatId=="5") { //taiwan_north
      return (

        <div>
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/5/0">{t('all')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/5/19">{t('taipei')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/5/20">{t('new_taipei_city')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/5/21">{t('keelung')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/5/22">{t('taoyuan')}</a> |
        <a style={{color: "#191919", textDecoration: 'none',marginLeft: 3}} href="/events/235/5/23">{t('hsinchu')}</a>
        </div>
      );
    } else if (districtCatId=="6") { //taiwan_middle
      return (

        <div>
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/6/0">{t('all')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/6/24">{t('miaoli')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/6/25">{t('taichung')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/6/26">{t('changhua')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/6/27">{t('nantou')}</a> |
        <a style={{color: "#191919", textDecoration: 'none',marginLeft: 3}} href="/events/235/6/28">{t('yunlin')}</a>
        </div>
      );
    } else if (districtCatId=="7") { //taiwan_south
      return (
        <div>
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/7/0">{t('all')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/7/29">{t('chiayi')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/7/30">{t('tainan')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/7/31">{t('pingtung')}</a> |
        <a style={{color: "#191919", textDecoration: 'none',marginLeft: 3}} href="/events/235/7/32">{t('kaohsiung')}</a>
        </div>
      );
    } else if (districtCatId=="8") { //taiwan_east
      return (
        <div>
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/8/0">{t('all')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/8/33">{t('yilan')}</a> |
        <a style={{color: "#191919", textDecoration: 'none', marginLeft: 3}} href="/events/235/8/34">{t('hualien')}</a> |
        <a style={{color: "#191919", textDecoration: 'none',marginLeft: 3}} href="/events/235/8/35">{t('taitung')}</a>
        </div>
      );
    } else {
      return(
        <div>
        </div>
      );
    }

  }
}

export default withTranslation()(District);
