import React, { Component } from 'react'
import '../../../i18n';
import { withTranslation } from "react-i18next";
import { Navbar } from "../Navbar";
import HomepageFooter from '../Footers/HomepageFooter';
import { AboutUsMain } from "./AboutUsMain";

class AboutUs extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Navbar />
          <div style={{marginTop: 120}}/>
          <AboutUsMain />
          <div style={{marginBottom: 100}}/>
        <HomepageFooter />
      </div>
    )
  }
}

export default withTranslation()(AboutUs);
