import styled from "styled-components";
import { MenuToggle } from "./menuToggle";
import React, { useState,useEffect } from "react";
import { Accessibility } from "./accessibility";
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";

const MobileNavLinksContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index:9999999999999999;

`;

const LinksWrapper = styled.ul`

  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  background-color: #fff;
  flex-direction: column;
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  z-index:9999999999999999;
`;

const LinkItem = styled.li`
  width: 100%;
  padding: 0 1.1em;
  color: #191919;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  margin-bottom: 10px;
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`;

const Marginer = styled.div`
  height: 2em;
`;

export function MobileNavLinks(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  }

  return (
    <MobileNavLinksContainer>
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      {isOpen &&<LinksWrapper>
        <Marginer />
        <LinkItem><Link href="/home">{t('home')}</Link></LinkItem>
        <LinkItem><Link href="/events/0/0/0">{t('events')}</Link></LinkItem>
        {/*
          <LinkItem><Link href="/events_records">{t('event_records')}</Link></LinkItem>
        */}
        {/* <LinkItem><Link href="/about_us">{t('about_us')}</Link></LinkItem>
        <LinkItem><Link href="/clients">{t('clients')}</Link></LinkItem> */}
        <LinkItem><Link href="/join_us">{t('join_us')}</Link></LinkItem>

        <LinkItem><Link onClick={() => changeLanguage('zh_tw')}>中文</Link></LinkItem>
        <LinkItem><Link onClick={() => changeLanguage('en')}>EN</Link></LinkItem>
        {isLoggedIn ? (
          <LinkItem><Link onClick={handleLogout}>{t('logout')}</Link></LinkItem>
        ) : (
          <LinkItem><Link href="/login">{t('login')}</Link></LinkItem>
        )}

        {/*
        <LinkItem onClick={() => changeLanguage('zh_tw')}>中文</LinkItem>
        <LinkItem onClick={() => changeLanguage('en')}>EN</LinkItem>
        */}
        
        <Marginer />
        
        {/*
        <Accessibility />
        */}
      </LinksWrapper>}
    </MobileNavLinksContainer>
  );
}
